import { clone, fixedNum, get, loop, parseNum, set, slug } from "@dex/bubl-helpers";
import { read, utils } from 'xlsx';
import { HHMMSS, MM } from "./sharedGeneral";
import { dataFormatAutoAvgRows, dataFormatRow } from "./sharedTestData";
import * as _ from 'lodash';
import BigNumber from "bignumber.js";
import { getDecimalTime, getMinutesTime, getPaceFromKph } from "./sharedTests";

export const labTestParseImport = async (url, session = {}, labTest: any = {}, onError) => {

    const file = await (await fetch(url)).arrayBuffer();

    const workbook = read(file);

    const excelData = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

    const data: any = [];
    const headers: any = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];

    let interval = (labTest?.type === "Sprint") ? 1 : 15;

    loop(headers, (label) => {

        labTestMapKey(label, onError);

    });

    let time = 0;

    loop(excelData, (cells, index) => {

        const row: any = {};

        loop(cells, (value, label) => {

            const key = labTestMapKey(label);

            if (!index && key === "time" && value) interval = value;

            row[key] = value;

        });

        delete (row.timehhmmss);
        delete (row.min);

        // if only 3 cells of data in this row.
        // exclude
        if (Object.keys(row).length < 3) return;

        if (!row.time) row.time = new BigNumber(interval).multipliedBy(index + 1).toFixed(0);
        if (labTest?.type === "Sprint" && !index) row.time = 0;

        if (row.pace && row.speed) row.pace = getPaceFromKph(row.speed);

        row.timeH = HHMMSS(row.time);
        row.timeM = MM(row.time);

        row._space = " | ";
        row._duration = row.time - time;

        time = row.time;

        //sum total energy
        row.cho_fat_pro = parseNum(row.cho) + parseNum(row.fat) + parseNum(row.pro);

        data.push(row);

    });

    const calcData = labTestCalcData(data, session, labTest);

    return calcData;

}

export const labTestCalcData = (rawData, session, labTest) => {

    const data: any = [];

    const activity = slug(labTest.activity);

    const restingFev1 = get(session, "health.restingFev1");
    const bodyWeight = get(session, "bio.weight");

    let copIndex = null;

    loop(rawData, (row, index) => {

        //rer >= 1 / rq point / carb only point
        if (!copIndex && row.rer >= 1) {
            copIndex = index;
        }

    });

    loop(rawData, (cells, index) => {

        const row = cells;

        row.cop = (index === copIndex) ? 1 : 0;

        const prev = rawData[index - 1] || null;
        const next = rawData[index + 1] || null;

        row.calc_cho_10 = row.cho ? (row.cho / 100) * 10 : 0;
        row.calc_cho_30 = row.cho ? (row.cho / 100) * 30 : 0;
        row.calc_eecho_10 = row.eecho ? (row.eecho / 100) * 10 : 0;
        row.calc_eecho_30 = row.eecho ? (row.eecho / 100) * 30 : 0;

        row.calc_cho = (row.eecho / row.ee) * 100;
        row.calc_fat = ((row.eefat + row.eepro) / row.ee) * 100;
        row.calc_pro = (row.eepro / row.ee) * 100;

        row.calc_vo2hr = (row.vo2relative && row.hr) ? (row.vo2relative / row.hr) * 100 : 0;
        row.calc_vo2bf = (row.vo2relative && row.rf) ? (row.vo2relative / row.rf) : 0;
        row.calc_tvx10 = (row.tvl) ? row.tvl * 10 : 0;
        row.calc_tvfev1 = (row.tvl && restingFev1) ? (row.tvl / restingFev1) * 100 : 0;
        row.calc_rffev1 = (row.rf && restingFev1) ? (row.rf / restingFev1) : 0;

        if (activity === "cycling") {
            row.calc_economy = (row.ee) ? ((row.power_raw * .8598) / row.ee) * 100 : 0;
            row.calc_power_raw_kg = row.power_raw && bodyWeight ? (row.power_raw / bodyWeight) : 0;

        } else {
            row.calc_economy = (row.vo2relative) ? (row.vo2relative / (row.speed * .2778)) : 0;
            row.calc_speed_kg = row.speed && bodyWeight ? (row.speed / bodyWeight) : 0;
            row.calc_pace = getMinutesTime(getPaceFromKph(row.speed));
        }

        if (prev) {
            row.calc_met = (prev && prev.calc_cho < 0.5 && row.calc_cho >= 0.5) ? Math.ceil(row.time / 60) : null;
        }

        if (next) {
            row.calc_wlabels = (row.speed && next.speed && Math.abs(next.speed - row.speed) > 0.2) ? row.speed : "";
        }

        data.push(row);

    });

    return data;

}

export const labTestFormatRowValues = (rawData) => {

    const data: any = [];

    loop(rawData, (cells, index) => {

        const row: any = {};

        loop(cells, (value, key) => {

            row[key] = labTestFormatRowValue(key, value);

        })

        data.push(row);

    });

    return data;

}

export const labTestFormatRowValue = (key, value) => {

    if (key === 'vo2relative') value = fixedNum(value, 1);
    else if (key === 'vo2absolute') value = fixedNum(value, 2);
    else if (key === 'vco2') value = fixedNum(value, 2);
    else if (key === 'hr') value = fixedNum(value, 0);
    else if (key === 'rf') value = fixedNum(value, 0); //changed to one to
    else if (key === 'tvl') value = fixedNum(value, 1);
    else if (key === 've') value = fixedNum(value, 0);
    else if (key === 'rer') value = fixedNum(value, 2);
    else if (key === 'cho') value = fixedNum(value, 0);
    else if (key === 'fat') value = fixedNum(value, 0);
    else if (key === 'pro') value = fixedNum(value, 0);
    else if (key === 'cho_fat_pro') value = fixedNum(value, 0);
    else if (key === 'eecho') value = fixedNum(value, 0);
    else if (key === 'eefat') value = fixedNum(value, 0);
    else if (key === 'eepro') value = fixedNum(value, 0);
    else if (key === 'ee') value = fixedNum(value, 0);

    else if (key === 'smo2_1') value = fixedNum(value, 0);
    else if (key === 'thb_1') value = fixedNum(value, 1);
    else if (key === 'smo2_2') value = fixedNum(value, 0);
    else if (key === 'thb_2') value = fixedNum(value, 1);
    // else if (key === 'smo2') value = fixedNum(value, 2);
    // else if (key === 'thb') value = fixedNum(value, 2);

    else if (key === 'power_raw') value = fixedNum(value, 0);
    else if (key === 'power_target') value = fixedNum(value, 0);
    else if (key === 'rpm') value = fixedNum(value, 0);
    else if (key === 'speed') value = fixedNum(value, 1);
    else if (key === 'grade') value = fixedNum(value, 1);

    else if (key === 'calc_cho_10') value = fixedNum(value, 0);
    else if (key === 'calc_cho_30') value = fixedNum(value, 0);
    else if (key === 'calc_eecho_10') value = fixedNum(value, 0);
    else if (key === 'calc_eecho_30') value = fixedNum(value, 0);

    else if (key === 'calc_cho') value = fixedNum(value, 0);
    else if (key === 'calc_fat') value = fixedNum(value, 0);
    else if (key === 'calc_pro') value = fixedNum(value, 1);
    else if (key === 'calc_vo2hr') value = fixedNum(value, 2);
    else if (key === 'calc_vo2bf') value = fixedNum(value, 2);
    else if (key === 'calc_tvx10') value = fixedNum(value, 2);
    else if (key === 'calc_tvfev1') value = fixedNum(value, 0);
    else if (key === 'calc_rffev1') value = fixedNum(value, 0);
    else if (key === 'calc_wlabels') value = value ? fixedNum(value, 1) : value;
    else if (key === 'calc_fatmax') value = fixedNum(value, 1);
    else if (key === 'calc_economy') value = fixedNum(value, 1);
    else if (key === 'calc_power_raw_kg') value = fixedNum(value, 1);
    else if (key === 'calc_speed_kg') value = fixedNum(value, 1);
    else if (key === 'lactate') value = fixedNum(value, 1);
    else if (key === 'vla') value = fixedNum(value, 2);

    return value;

}

export const labTestFormatChartValues = (rows) => {

    const data: any = [];

    loop(rows || [], (cells, index) => {

        const row: any = {};

        loop(cells || [], (value, key) => {

            row[key] = labTestFormatChartValue(key, value);

        })

        data.push(row);

    });

    return data;

}

export const labTestFormatChartValue = (key, value) => {

    if (key === 'power_raw') value = fixedNum(value, 0);
    else if (key === 'power_target') value = fixedNum(value, 0);
    else if (key === 'fev1FvcPercentage') value = fixedNum(value, 1);
    else if (key === 'restingFev1') value = fixedNum(value, 1);
    else if (key === 'restingFvc') value = fixedNum(value, 1);
    else if (key === 'rf') value = fixedNum(value, 0);

    return value;

}

export const labTestMapKey = (label, onError?: Function | undefined) => {

    let key = slug(label, { replacement: '_' }).replace("-", "_");

    const map = {
        "effort": "effort",
        "stage": "stages",
        "stages": "stages",
        "avg": "averages",
        "to_avg": "averages",
        "averages": "averages",
        "average": "averages",
        "sec": "time",
        "times": "time",
        "timehhmmss": "timehhmmss",
        "min": "min",
        "vo2mlkgmin": "vo2relative",
        "vo2mlmin": "vo2absolute",
        "vo2lmin": "vo2absolute",
        "vco2lmin": "vco2",
        "hrbpm": "hr",
        "hr": "hr",
        "pace": "pace",
        "speed_raw": "speed",
        "speed_kph": "speed",
        "speed_kph_1": "speed",
        "speed": "speed",
        "speed_1": "speed_1",
        "rpm": "rpm",
        "power_target": "power_target",
        "power_raw": "power_raw",
        "power_bike": "power_raw",
        "power_trainer": "power_target",
        "power_bike_w": "power_raw",
        "power_trainer_w": "power_target",
        "rfbpm": "rf",
        "tvl": "tvl",
        "velmin": "ve",
        "rer": "rer",
        "cho_ghr": "cho",
        "fat_ghr": "fat",
        "pro_ghr": "pro",
        "cho_g": "cho",
        "fat_g": "fat",
        "pro_g": "pro",
        "eecho": "eecho",
        "eefat": "eefat",
        "eepro": "eepro",
        "ee": "ee",
        "ee_total": "ee",
        "smo2": "smo2_1",
        "thb": "thb_1",
        "smo2_1": "smo2_1",
        "thb_1": "thb_1",
        "smo2_2": "smo2_2",
        "thb_2": "thb_2",
        "grade_percent": "grade",
        "grade": "grade",
    };

    if (map[key]) key = map[key];
    else if (!map[key] && typeof onError === "function") onError("Unknown Column: " + label);

    return key;

}

export const labTestFormattedData = (rawData, settings) => {

    let data: any = [];

    loop(rawData, (row, index) => {

        row = dataFormatRow(row, index, settings || {});

        data.push(row);

    });

    if (settings.mode !== "manual") data = dataFormatAutoAvgRows(data, settings);

    return data;

}

export const labTestAverageData = (formattedData, settings) => {

    const data: any = [];

    const stages = {};

    loop(formattedData, (row) => {

        if (!row._stage && row._stage !== 0 && row._stage !== "0") return;

        if (!row._is.avg) return;

        if (!stages[row._stage]) stages[row._stage] = [];

        stages[row._stage].push(row);

    });

    loop(stages, (rows, index) => {

        const stage = labTestAverageRowsToStage(rows, index);

        data.push({ ...stage });

    });

    let avgData = data.filter(Boolean);

    avgData = labTestCalcDataPoints(avgData);

    return avgData;

}

export const labTestActiveData = (formattedData, settings) => {

    const data: any = [];

    const stages = {};

    loop(formattedData, (row) => {

        if (!row._stage && row._stage !== 0 && row._stage !== "0") return;

        if (!row._is.active) return;

        if (!stages[row._stage]) stages[row._stage] = [];

        stages[row._stage].push(row);

    });

    loop(stages, (rows, index) => {

        const stage = labTestAverageRowsToStage(rows, index);

        data.push({ ...stage });

    });

    let activeData = data.filter(Boolean);

    activeData = labTestCalcDataPoints(activeData);

    return activeData;

}

export const labTestCalcDataPoints = (avgData) => {

    let fatMax = 0;
    let fatMaxIndex = null;

    let mepIndex = null;

    loop(avgData, (row, index) => {

        //fatmax, max fat %
        if (fatMax <= row.fat) {
            fatMax = row.fat;
            fatMaxIndex = index;
        }

        //me point (between 47% - 53%)
        const total = row.cho_fat_pro || 0;
        const carbfat = (row.cho + row.fat) || 0;
        const range = [row.cho_fat_pro / 100 * 20, row.cho_fat_pro / 100 * 80];

        if (total && carbfat >= range[0] && carbfat <= range[1]) {
            mepIndex = index;
        }

    });

    loop(avgData, (cells, index) => {

        const row = cells;

        avgData[index].fatmax = (index === fatMaxIndex) ? 1 : 0;
        avgData[index].mep = (index === mepIndex) ? 1 : 0;

        if (row.fat && fatMax && row.fat >= (fatMax - 1) && row.fat <= (fatMax + 1)) {
            avgData[index].calc_fatmax = row.speed;
        }

    });

    return avgData;

}

export const labTestMergeData = (avgData, manualData) => {

    const data: any = [];

    avgData = avgData || [];
    manualData = manualData || [];

    loop(avgData, (row, index) => {

        const manual = manualData[index] || {};

        const parsedManual = {};

        for (const key in manual) {
            if (manual.hasOwnProperty(key)) {
                parsedManual[key] = (manual[key] === undefined || manual[key] === null || manual[key] === "") ? null : parseNum(manual[key]);
            }
        }

        data.push({ ...row, ...parsedManual });

    });

    return data;

}

export const labTestAverageRowsToStage = (rows, index) => {

    const stage: any = {
        _stage: index,
        _rows: [],
        _duration: 0,
        pace: 0
    };

    loop(rows, (row, i) => {

        stage._rows.push(row._index);

        stage._duration += row._duration;

        loop(row, (value, key) => {

            if (key[0] === "_") return;

            //first instance only
            if (["timeH", "timeM", "time"].includes(key)) {
                // if (!stage[key]) stage[key] = value;
                stage[key] = value;
                return;
            }

            if (stage[key] === undefined) stage[key] = 0;

            if (isNaN(stage[key])) stage[key] = 0;

            stage[key] += value;

        });

    });

    loop(stage, (value, key) => {

        if (key[0] === "_") return;
        if (["timeS", "timeH", "timeM", 'time', "pace"].includes(key)) return;
        const sum = value / stage._rows.length;

        if (["speed"].includes(key)) {
            stage["pace"] = getPaceFromKph(sum)
        }

        stage[key] = labTestFormatRowValue(key, sum);

    });

    if (!stage._rows.length) return;

    stage._rows = stage._rows[0] + "-" + stage._rows[stage._rows.length - 1];

    return stage;

}

export const labTestRollingData = (formattedData, settings) => {

    const data: any = [];

    const rollingPeriod = parseNum(settings.rollingPeriod || 60);
    const singlePeriod = parseNum(formattedData[0]?.time || 15);

    const rollingCount = rollingPeriod / singlePeriod;

    loop(formattedData, (row, index) => {

        let rows: any = [row];

        while (rows.length <= rollingCount - 1) {

            rows.push(formattedData[index + rows.length] || null);

        }

        rows = rows.filter(Boolean);

        if (rows.length < rollingCount) return;

        //average
        const stage = labTestAverageRowsToStage(rows, index + 1);

        data.push(stage);

    });

    return data;

}

export const labTestSumData = (rolling, settings) => {

    const data: any = {};
    const grouped: any = {};
    const full: any = { rer: [], vo2absolute: [] };

    // //rer and vo2
    // full.rer.push(row.rer);
    // full.vo2absolute.push(row.vo2absolute);

    loop(rolling, (row, index) => {

        loop(row, (value, key) => {

            if (!grouped[key]) grouped[key] = [];

            grouped[key].push(value);

        })

    });

    loop(grouped, (values, key) => {

        if (key[0] === "_") return;

        if (["timeS", "timeH", "timeM"].includes(key)) {
            return;
        }

        data[key] = {
            min: labTestFormatRowValue(key, _.min(values)),
            max: labTestFormatRowValue(key, _.max(values)),
            avg: labTestFormatRowValue(key, _.sum(values) / values.length),
        }

        if (["speed"].includes(key)) {

            data["pace"] = {
                min: getPaceFromKph(data[key].min),
                max: getPaceFromKph(data[key].max),
                avg: getPaceFromKph(data[key].avg)
            }

        }

    });

    loop(full, (values, key) => {

        data["full_" + key] = {
            min: labTestFormatRowValue(key, _.min(values)),
            max: labTestFormatRowValue(key, _.max(values)),
            avg: labTestFormatRowValue(key, _.sum(values) / values.length),
        }

    });

    return data;

}

export const labTestDataColumns = (type, activity, mode, format) => {

    type = (type || "").toLowerCase();
    activity = (activity || "").toLowerCase();

    const cycling = activity === "cycling";
    const running = activity === "running";
    const raw = format === "raw";
    const avg = format === "avg";

    let columns: any = [
        avg ? {
            label: "Stage",
            desc: "#",
            key: "_stage",
            //format: "number",
        } : "",
        avg ? {
            label: "Rows",
            desc: "#-#",
            key: "_rows",
        } : "",
        raw ? {
            label: "Row",
            desc: "#",
            key: "_index",
            //format: "number",
        } : "",
        !avg && mode === "manual" && type === "race ready" ? {
            label: "Effort",
            desc: "#",
            key: "effort",
        } : "",
        !avg && mode === "manual" ? {
            label: "Stage",
            desc: "#",
            key: "stages",
        } : "",
        !avg && mode === "manual" ? {
            label: "Average",
            desc: "#",
            key: "averages",
        } : "",
        {
            label: "Time",
            desc: "[hh:mm:ss]",
            key: "timeH",
        },
    ];

    if (type === "rmr") {

        columns = [
            ...columns,
            {
                label: "VO2",
                desc: "[mL/kg/min]",
                key: "vo2relative",
            },
            {
                label: "VO2",
                desc: "[L/min]",
                key: "vo2absolute",
            },
            {
                label: "VCO2",
                desc: "[L/min]",
                key: "vco2",
            },

            {
                label: "HR",
                desc: "[bpm]",
                key: "hr",
            },
            {
                label: "Rf",
                desc: "[bpm]",
                key: "rf",
            },
            {
                label: "Tv",
                desc: "[L]",
                key: "tvl",
            },
            {
                label: "Ve",
                desc: "[L/min]",
                key: "ve",
            },
            {
                label: "RER",
                desc: "",
                key: "rer",
            },
            {
                label: "CHO",
                desc: "[g/hr]",
                key: "cho",
            },
            {
                label: "FAT",
                desc: "[g/hr]",
                key: "fat",
            },
            {
                label: "PRO",
                desc: "[g/hr]",
                key: "pro",
            },
            {
                label: "EECHO",
                desc: "[Kcal/hr]",
                key: "eecho",
            },
            {
                label: "EEFAT",
                desc: "[Kcal/hr]",
                key: "eefat",
            },
            {
                label: "EEPRO",
                desc: "[Kcal/hr]",
                key: "eepro",
            },
            {
                label: "EE",
                desc: "[Kcal/hr]",
                key: "ee",
            },
        ];

    } else if (type === "race ready") {

        columns = [
            ...columns,
            cycling ? {
                label: "Power",
                desc: "Target [W]",
                key: "power_target",
            } : "",
            cycling ? {
                label: "Power",
                desc: "Raw [W]",
                key: "power_raw",
            } : "",
            cycling ? {
                label: "RPM",
                desc: "-",
                key: "rpm",
            } : "",
            running ? {
                label: "Speed",
                desc: "[kph]",
                key: "speed",
            } : "",
            {
                label: "VO2",
                desc: "[mL/kg/min]",
                key: "vo2relative",
            },
            {
                label: "VO2",
                desc: "[L/min]",
                key: "vo2absolute",
            },
            {
                label: "VCO2",
                desc: "[L/min]",
                key: "vco2",
            },
            {
                label: "HR",
                desc: "[bpm]",
                key: "hr",
            },

            {
                label: "Rf",
                desc: "[bpm]",
                key: "rf",
            },
            {
                label: "Tv",
                desc: "[L]",
                key: "tvl",
            },
            {
                label: "Ve",
                desc: "[L/min]",
                key: "ve",
            },
            {
                label: "RER",
                desc: "-",
                key: "rer",
            },
            {
                label: "CHO",
                desc: "[g/hr]",
                key: "cho",
            },
            {
                label: "FAT",
                desc: "[g/hr]",
                key: "fat",
            },
            {
                label: "PRO",
                desc: "[g/hr]",
                key: "pro",
            },
            {
                label: "Total",
                desc: "[g/hr]",
                key: "cho_fat_pro",
            },
            {
                label: "EECHO",
                desc: "[Kcal/hr]",
                key: "eecho",
            },
            {
                label: "EEFAT",
                desc: "[Kcal/hr]",
                key: "eefat",
            },
            {
                label: "EEPRO",
                desc: "[Kcal/hr]",
                key: "eepro",
            },
            {
                label: "EE",
                desc: "[Kcal/hr]",
                key: "ee",
            },
            {
                label: "SMO2 (1)",
                desc: "[%]",
                key: "smo2_1",
            },
            {
                label: "THB (1)",
                desc: "[mg/L]",
                key: "thb_1",
            },
            {
                label: "SMO2 (2)",
                desc: "[%]",
                key: "smo2_2",
            },
            {
                label: "THB (2)",
                desc: "[mg/L]",
                key: "thb_2",
            },
            running ? {
                label: "Grade",
                desc: "%",
                key: "grade",
            } : "",
            {
                label: "",
                desc: "",
                key: "_space"
            },
            {
                label: "CHO",
                desc: "%",
                key: "calc_cho",
            },
            {
                label: "Fat",
                desc: "%",
                key: "calc_fat",
            },
            {
                label: "PRO",
                desc: "%",
                key: "calc_pro",
            },
            {
                label: "MET",
                desc: "-",
                key: "calc_met",
            },
            {
                label: "VO2/HR",
                desc: "[ml/beat]",
                key: "calc_vo2hr",
            },
            {
                label: "VO2/BF",
                desc: "[ml/breath]",
                key: "calc_vo2bf",
            },
            {
                label: "TVx10",
                desc: "[L]",
                key: "calc_tvx10",
            },
            {
                label: "TV/FEv1	",
                desc: "[%]",
                key: "calc_tvfev1",
            },
            {
                label: "Rf/FEv1	",
                desc: "[%]",
                key: "calc_rffev1",
            },
            raw ? {
                label: "W Labels",
                desc: "-",
                key: "calc_wlabels",
            } : "",
            avg ? {
                label: "Speed at Fatmax",
                desc: "[kph]",
                key: "calc_fatmax",
            } : "",
            {
                label: "Economy",
                desc: running ? "[(ml/kg/min) / (min/s)]" : "[W / (Kcal/hr)]",
                key: "calc_economy",
            },
        ];

    } else if (type === "sprint") {

        columns = [
            ...columns,
            cycling ? {
                label: "Power",
                desc: "Trainer [W]",
                key: "power_target",
            } : "",
            cycling ? {
                label: "Power",
                desc: "Bike [W]",
                key: "power_raw",
            } : "",
            cycling ? {
                label: "RPM",
                desc: "#",
                key: "rpm",
            } : "",
            running ? {
                label: "Speed",
                desc: "[kph]",
                key: "speed",
            } : "",
            running ? {
                label: "Pace",
                desc: "[min/km]",
                key: "pace",
            } : "",
            {
                label: "HR",
                desc: "[bpm]",
                key: "hr",
            },
            {
                label: "SMO2 (1)",
                desc: "[%]",
                key: "smo2_1",
            },
            {
                label: "THB (1)",
                desc: "[mg/L]",
                key: "thb_1",
            },
            {
                label: "SMO2 (2)",
                desc: "[%]",
                key: "smo2_2",
            },
            {
                label: "THB (2)",
                desc: "[mg/L]",
                key: "thb_2",
            },
        ];
    } else {

        columns = [
            ...columns,
            cycling ? {
                label: "Power",
                desc: "Target [W]",
                key: "power_target",
            } : "",
            cycling ? {
                label: "Power",
                desc: "Raw [W]",
                key: "power_raw",
            } : "",
            cycling ? {
                label: "RPM",
                desc: "#",
                key: "rpm",
            } : "",
            running ? {
                label: "Speed",
                desc: "[kph]",
                key: "speed",
            } : "",
            running ? {
                label: "Grade",
                desc: "%",
                key: "grade",
            } : "",
            {
                label: "VO2",
                desc: "[mL/kg/min]",
                key: "vo2relative",
            },
            {
                label: "VO2",
                desc: "[L/min]",
                key: "vo2absolute",
            },
            {
                label: "VCO2",
                desc: "[L/min]",
                key: "vco2",
            },
            {
                label: "HR",
                desc: "[bpm]",
                key: "hr",
            },

            {
                label: "Rf",
                desc: "[bpm]",
                key: "rf",
            },
            {
                label: "Tv",
                desc: "[L]",
                key: "tvl",
            },
            {
                label: "Ve",
                desc: "[L/min]",
                key: "ve",
            },
            {
                label: "RER",
                desc: "#",
                key: "rer",
            },
            {
                label: "CHO",
                desc: "[g/hr]",
                key: "cho",
            },
            {
                label: "FAT",
                desc: "[g/hr]",
                key: "fat",
            },
            {
                label: "PRO",
                desc: "[g/hr]",
                key: "pro",
            },
            {
                label: "Total",
                desc: "[g/hr]",
                key: "cho_fat_pro",
            },
            {
                label: "EECHO",
                desc: "[Kcal/hr]",
                key: "eecho",
            },
            {
                label: "EEFAT",
                desc: "[Kcal/hr]",
                key: "eefat",
            },
            {
                label: "EEPRO",
                desc: "[Kcal/hr]",
                key: "eepro",
            },
            {
                label: "EE",
                desc: "[Kcal/hr]",
                key: "ee",
            },

            {
                label: "SMO2 (1)",
                desc: "[%]",
                key: "smo2_1",
            },
            {
                label: "THB (1)",
                desc: "[mg/L]",
                key: "thb_1",
            },
            {
                label: "SMO2 (2)",
                desc: "[%]",
                key: "smo2_2",
            },
            {
                label: "THB (2)",
                desc: "[mg/L]",
                key: "thb_2",
            },

            {
                label: "",
                desc: "",
                key: "_space"
            },
            {
                label: "CHO",
                desc: "%",
                key: "calc_cho",
            },
            {
                label: "Fat",
                desc: "%",
                key: "calc_fat",
            },
            {
                label: "PRO",
                desc: "%",
                key: "calc_pro",
            },
            {
                label: "MET",
                desc: "-",
                key: "calc_met",
            },
            {
                label: "VO2/HR",
                desc: "[ml/beat]",
                key: "calc_vo2hr",
            },
            {
                label: "VO2/BF",
                desc: "[ml/breath]",
                key: "calc_vo2bf",
            },
            {
                label: "TVx10",
                desc: "[L]",
                key: "calc_tvx10",
            },
            {
                label: "TV/FEv1	",
                desc: "[%]",
                key: "calc_tvfev1",
            },
            {
                label: "Rf/FEv1	",
                desc: "[%]",
                key: "calc_rffev1",
            },
            raw ? {
                label: "W Labels",
                desc: "-",
                key: "calc_wlabels",
            } : "",
            avg ? {
                label: "Speed at Fatmax",
                desc: "[kph]",
                key: "calc_fatmax",
            } : "",
            {
                label: "Economy",
                desc: running ? "[(ml/kg/min) / (min/s)]" : "[W / (Kcal/hr)]",
                key: "calc_economy",
            },
        ];

    }

    return columns.filter(Boolean);

}

export const labTestCalcZoneTime = (data, sum, key, zone) => {

    if (!sum || !sum[key]) return 0;

    let time = 0;
    let breakpoint = sum[key].max * (zone / 100);
    let totalDuration = 0;

    loop(data, (row, index) => {

        if (row[key] >= zone) {
            time += row?._duration;
        }

        totalDuration += row?._duration;

    });

    return fixedNum((time / totalDuration) * 100, 0);

}

export const labTestGetMePoint = (data = []) => {

    let _row: any = {};

    loop(data, (row, index) => {

        if (row.mep) _row = row;

    });

    return _row;

}

export const labTestGetCoPoint = (data = [], fatPercentPoint) => {

    let _row: any;
    let lowest: any;

    loop(data, (row, index) => {

        const prev: any = data[index - 1];

        if (!prev) return;
        if (_row) return;

        if (prev.calc_fat >= fatPercentPoint && row.calc_fat < fatPercentPoint) _row = clone(prev);

        if (!lowest || row.calc_fat <= lowest.calc_fat) lowest = clone(row);

    });

    lowest.isLowest = true;

    return _row || lowest || {};

}

export const labTestGetSMO2Drop = (sumData = {}) => {

    const output = { 1: '', 2: '' };

    loop([1, 2], (index) => {

        const key = "smo2_" + index;
        const smo2 = get(sumData, key, {});

        if (smo2.max && smo2.min) {

            const value = smo2.max - smo2.min;

            output[index] = fixedNum(value, 2);

        }

    });

    return output;

}

export const labTestCalcFatMaxFromSpeed = (load, activity, data = [], speedType = "") => {

    const values = {
        fatBurnG: 0,
        fatBurnKcal: 0,
        carbBurnG: 0,
        carbBurnKcal: 0,
        fatBurnPercent: 0
    }

    const isSubmax = speedType === "submaxSpeed";

    let key = isSubmax ? "submaxSpeed" : activity === "Running" ? "speed" : "power_raw";

    let above: any;
    let below: any;

    loop(data, (row, index) => {

        const prev = data[index - 1];
        const next = data[index + 1];

        if (!next) return;

        if (load === row[key]) { //if exact match, use this row only
            above = clone(row);
            below = clone(row);
        } else if (load > row[key] && load < next[key]) {
            above = clone(next);
            below = clone(row);
        }

    });

    if (!above || !below) return values;

    const loadDiff = above[key] - below[key];
    let loadPercent = ((load - below[key]) / loadDiff);

    if (isNaN(loadPercent)) loadPercent = 0;

    values.fatBurnG = fixedNum(((above.fat - below.fat) * loadPercent) + below.fat, 0);
    values.carbBurnG = fixedNum(((above.cho - below.cho) * loadPercent) + below.cho, 0);
    values.fatBurnPercent = fixedNum(((above.calc_fat - below.calc_fat) * loadPercent) + below.calc_fat, 0);

    values.fatBurnKcal = fixedNum(values.fatBurnG * 9, 0);
    values.carbBurnKcal = fixedNum(values.carbBurnG * 4.2, 0);

    return values;

}

export const labTestGetFatMax = (data = [], max, activity) => {

    let key = activity === "Running" ? "speed" : "power_raw";

    let _row: any;

    const loadPoints: any = [];

    loop(data, (row, index) => {

        loadPoints.push(row[key]);

    });

    const closest = loadPoints.sort((a, b) => Math.abs(max - a) - Math.abs(max - b))[0];

    loop(data, (row, index) => {

        if (row[key] === closest) _row = row;

    });

    return _row || {};

}

export const labTestGetNutritionData = (data, activity) => {

    const nutrition: any = [];

    const fatmax = data.energy?.fatmaxLoad;
    const mep = data.energy?.mepLoad;
    const mepFatBurn = data.energy?.mepFatPercent;

    const max = Math.max(fatmax, mep);

    const carbOnly = labTestGetCoPoint(data.avgData, 20);

    const fatBurn = labTestGetFatMax(data.avgData, max, activity);

    const upper = get(data, "energy.upperRefuel", 30);
    const lower = get(data, "energy.lowerRefuel", 10);

    nutrition.push({
        "zone": "Fat-Burning Primarily",
        "power_raw": "<" + fixedNum(mep, 0),
        "speed": "<" + (fatBurn.speed || ""),
        "hr": "<" + (fatBurn.hr || ""),
        "eerefuel": "<" + fixedNum(data.energy?.mepCarbkcal * (upper / 100), 0),
        "refuel": "<" + fixedNum(data.energy?.mepCarbg * (upper / 100), 0),
        "fat": ">" + (mepFatBurn || ""),
        "rows": fatBurn._rows || fatBurn.__index,
    });

    nutrition.push({
        "zone": "Mixed Carb & Fat-Burning",
        "power_raw": fixedNum(mep, 0) + "-" + fixedNum(carbOnly.power_raw, 0),
        "speed": fatBurn.speed + "-" + carbOnly.speed,
        "hr": fatBurn.hr + "-" + carbOnly.hr,
        "eerefuel": fixedNum(fatBurn.calc_eecho_30 / 30 * upper, 0) + "-" + fixedNum(carbOnly.calc_eecho_30 / 30 * upper, 0),
        "eefat": fatBurn.eefat + "-" + carbOnly.eefat,
        "refuel": fixedNum(fatBurn.calc_cho_30 / 30 * upper, 0) + "-" + fixedNum(carbOnly.calc_cho_30 / 30 * upper, 0),
        "fat": carbOnly.calc_fat + "-" + (mepFatBurn || ""),
        // "fat": fatBurn.fat + "-" + carbOnly.fat,
        "rows": "",
    });

    if (carbOnly.isLowest) {

        nutrition.push({
            "zone": "Carb-Burning Primarily",
            "rows": carbOnly._rows || carbOnly.__index,
        });

    } else {

        nutrition.push({
            "zone": "Carb-Burning Primarily",
            "power_raw": ">" + fixedNum(carbOnly.power_raw, 0),
            "speed": ">" + carbOnly.speed,
            "hr": ">" + carbOnly.hr,
            "eerefuel": ">" + fixedNum(carbOnly.calc_eecho_30 / 30 * upper, 0),
            "refuel": ">" + fixedNum(carbOnly.calc_cho_30 / 30 * upper, 0),
            "fat": "<" + carbOnly.calc_fat,
            "rows": carbOnly._rows || carbOnly.__index,
        });

    }

    return nutrition;

}

export const labTestGetPeakEconomy = (economy, data) => {

    let row: any = null;

    economy = parseNum(economy);

    loop(data, (item) => {

        if (!row && parseNum(item.calc_economy) === economy) row = item;

    });

    return row || {};

};


export const labTestGetPrefZones = (lactate) => {

    const zones = {};

    loop(lactate?.zones, (zone) => {

        if (zone.zone) zones[zone.zone] = zone;

    });

    return zones;

}


export const labTestGetPrefZonesSplitTempo = (lactate) => {

    const output = {};

    const zones = labTestGetPrefZones(lactate);

    const indexes = [1, 2, 3, 4, 5];

    loop(indexes, (index) => {

        const current = zones[index];

        const next = zones[index]?.zone == 5 ? null : zones[index + 1];

        const zone = { min: current, max: next };

        output[index] = zone;

    });

    const halfHr = (output[2].max?.hr + output[2].min?.hr) / 2

    const halfPace = (parseFloat(output[2].max?.pace_adj) + parseFloat(output[2].min?.pace_adj)) / 2

    const hours = Math.floor(halfPace);

    const minutes = Math.round((halfPace - hours) * 60);

    const result = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');

    output[6] = output[5];
    output[5] = output[4];
    output[4] = output[3];
    output[3] = { ...output[2], min: { ...output[2].max, hr: halfHr, pace_adj: result } };
    output[2] = { ...output[2], max: { ...output[2].min, hr: halfHr, pace_adj: result } };
    output[1] = output[1];

    return output;

}

export const labTestGetPrefZone = (lactate, zone) => {

    const zones = labTestGetPrefZones(lactate);

    return zones[zone];

}

export const labTestGetStrides = (data) => {

    data = _.map(data, 'strideFrequency').map(parseFloat);

    const strides = {
        avg: fixedNum(_.mean(data), 1.0),
        min: _.min(data),
        max: _.max(data),
    };

    return strides;

}

export const labTestGetDuration = (data) => {

    if (!data.length) return 15;

    const time = data[data.length - 1].time;

    return time;

}

export const labTestGetEffortData = (data) => {

    const efforts = {};

    loop(data, (row, index) => {

        if (row.effort === undefined || row.effort === null) return;

        if (!efforts[row.effort]) efforts[row.effort] = [];

        // row.averages = row.stages;
        // row._is.avg = row.stages ? true : false;

        efforts[row.effort].push(row);

    });

    return Object.values(efforts);

}
export const labTestGetEffortAverages = (efforts) => {

    const averages: any = [];

    loop(efforts, (effort) => {

        if (!effort.length) return;

        const stageOne = effort[0];
        const stageTwo = effort[1];

        const duration = stageTwo?.time - stageOne?.time;
        const time = stageOne?.time - duration;

        const zero = {
            effort: effort[0].effort,
            _index: 0,
            stages: 0,
            _stage: 0,
            time: time,
            timeH: HHMMSS(time),
            timeM: MM(time),
            _is: { avg: true }
        };

        effort = [
            zero,
            ...effort
        ];

        let average = labTestAverageData(effort, {});

        average = labTestEffortStageTimeDifference(average);

        averages.push(average);

    });

    return averages;

}

export const labTestEffortStageTimeDifference = (effort) => {

    loop(effort, (stage, index) => {

        //skip first stage
        if (!index) return;

        const prev = effort[index - 1];

        effort[index]._time_diff = stage.time - prev.time;

    });

    return effort;

}