import React from 'react';
import { LineChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import * as _ from 'lodash';
import ChartAxis from '../Chart/ChartAxis';
import { slug } from '@dex/bubl-helpers';
import { fancyTimeFormat, nearestMaxTen } from '../../functions/sharedChart';


interface FooBar {
    foo: string,
    bar: number,
    [key: string]: any,
}

const ChartPerformance: React.FC<ChartLactateProps> = (props) => {

    let { data, activity, intensity, date, index, chartType, xAxisKey, isRunningPeak, filters } = props;

    const Axis = new ChartAxis();

    const xAxis = filters?.xAxis;

    if (isRunningPeak) {
        if (xAxis == "time") {
            Axis.x = {
                key: "time",
                index: 6,
                label: "Time - min",
                props: {
                    tickFormatter: fancyTimeFormat
                },

            };
        } else if (xAxis == "grade") {
            Axis.x = {
                key: "grade",
                index: 6,
                label: "% Grade",
                props: {
                    tickFormatter: (value) => value + "%",
                },
            };
        } else if (xAxis == "maxSpeed") {
            Axis.x = {
                key: "speed",
                index: 6,
                label: "Speed - KPH (Max Test)",
                props: {
                },
            };
        } else {
            Axis.x = {
                key: "submaxSpeed",
                index: 6,
                label: "Speed - KPH (Submax Test)",
                props: {
                },
            };
        }

    } else {
        Axis.setXByActivity(activity, intensity);
    }

    const historyData = Axis.prepareHistoryData(data, slug(date), slug(index));

    if (isRunningPeak && xAxis == "grade") {
        //Axis.x.props = { type: "category", interval: 0 };
    }

    Axis.x.props = {
        ...Axis.x.props,
        ...props.xAxis,
    };

    if (activity === "Running") {

        // if (intensity === "Peak") {
        //     Axis.x.key = "_stage";
        //     Axis.x.label = "Stage";
        //     Axis.x.props = {
        //         interval: 0,
        //         // type: "category",
        //         // ticks: ["1", "2", "3", "4"],
        //     }

        // }

        Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "strideLength",
            label: "Stride Length",
            axisLabel: "Stride Length - m",
            props: {
                connectNulls: true,
            }
        });

        // Axis.y.push({
        //     axis: false,
        //     line: true,
        //     pos: "right",
        //     index: 2,
        //     key: "grade",
        //     label: "grade",
        //     props: {
        //     }
        // });

        Axis.y.push({
            axis: true,
            line: true,
            pos: "right",
            index: 1,
            key: "strideFrequency",
            label: "Strides",
            axisLabel: "Strides per min",
            props: {
                domain: [60, 100],
                connectNulls: true,
            }
        });

    } else {
        if (chartType == "Power") {
            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: 0,
                key: "power_raw_" + slug(date),
                label: "Power " + date,
                axisLabel: "Power - W",
                props: {
                    connectNulls: true,
                    domain: [50, nearestMaxTen]
                }
            })
        } else {
            Axis.y.push({
                axis: true,
                line: true,
                pos: "left",
                index: 1,
                key: "rpm_" + slug(date),
                label: "RPM " + date,
                axisLabel: "RPM",
                props: {
                    connectNulls: true,
                }
            });
        }
    }


    if (date !== index) {

        chartType == "Power" ? Axis.y.push({
            axis: true,
            line: true,
            pos: "left",
            index: 0,
            key: "power_raw_" + slug(index),
            label: "Power " + index,
            axisLabel: "Power - W",
            props: {
                strokeDasharray: "6 4",
                connectNulls: true,
            }
        }) : null;

        chartType == "Rpm" ? Axis.y.push({
            axis: true,
            line: true,
            pos: "right",
            index: 1,
            key: "rpm_" + slug(index),
            label: "RPM " + index,
            props: {
                strokeDasharray: "6 4",
                connectNulls: true,
            }
        }) : null;
    }

    return (

        <Chart>

            <LineChart
                {...ChartProps.LineChart()}
                data={!index && activity === "Running" ? data : historyData}
            >

                {Axis.render()}

            </LineChart>

        </Chart>

    )

}

interface ChartLactateProps {
    [key: string]: any,
}

export default ChartPerformance;