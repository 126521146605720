import { clone, fixedNum, loop } from "@dex/bubl-helpers";
import { SimpleLinearRegression, PolynomialRegression } from 'ml-regression';
import * as _ from 'lodash';

export const chartRegressionLinear = (x, y) => {

    const regression = new SimpleLinearRegression(x, y);

    const plot: any = [];

    loop(y, (n, i) => {

        plot.push(regression.predict(x[i]));

    });

    return {
        regression: regression,
        score: regression.score(x, y),
        plot: plot
    }

}

export const chartRegressionPolynomial = (x, y, deg = 3) => {

    const regression = new PolynomialRegression(x, y, deg);

    const plot: any = [];

    loop(y, (n, i) => {

        plot.push(regression.predict(x[i]));

    });

    return {
        regression: regression,
        score: regression.score(x, y),
        plot: plot
    }

}

const getMultiple = (y, expectedY) => {

    let diff = expectedY - y;

    let multiple = 1.1;

    if (diff < 0) {

        multiple = 0.95;

        if (diff < -10) multiple = .5;
        else if (diff < -5) multiple = .75;
        else if (diff < -2) multiple = .8;
        else if (diff < -1) multiple = .9;

    } else {

        if (diff > 2) multiple = 1.2;
        else if (diff > 5) multiple = 1.25;
        else if (diff > 10) multiple = 1.5;
        else if (diff > 20) multiple = 1.75;
        else if (diff > 30) multiple = 1.9;
        else if (diff > 50) multiple = 2.0;

    }

    return multiple;

}

export const chartRegressionPredictX = (reg, minX, expectedY, tolerance = 0.05) => {

    let x = minX;

    let y = reg.predict(x);

    let near = { x, y };

    let nearest = {};

    nearest[near.y] = near.x;

    let moveBy = 0.02;

    for (let i = 0; i <= 10; i++) {

        let diff = Math.abs(near.y - expectedY);

        if (diff <= tolerance) return near.x;

        if (i > 50) moveBy = 0.01;
        else if (i > 75) moveBy = 0.005;

        near = chartRegressionNearer(reg, near.x, near.y, expectedY, moveBy);

        nearest[near.y] = near.x;

    }

    const keys = Object.keys(nearest).map(parseFloat);

    y = keys.reduce((prev, curr) => Math.abs(curr - expectedY) < Math.abs(prev - expectedY) ? curr : prev);

    x = nearest[y];

    return x;

}

const chartRegressionNearer = (reg, x, y, expectedY, moveBy = .03) => {

    const results = {};

    let up = x;
    let down = x;

    for (let i = 0; i < 6000; i++) {

        up = up + moveBy;

        results[reg.predict(up)] = up;

        // down = down - moveBy;

        // results[reg.predict(down)] = down;

    }

    const keys = Object.keys(results).map(parseFloat);

    y = keys.reduce((prev, curr) => Math.abs(curr - expectedY) < Math.abs(prev - expectedY) ? curr : prev);

    x = results[y];

    return { x, y };

}

// const chartRegressionNearer = (reg, x, y, expectedY) => {

//     const results = {};

//     let multiple = getMultiple(y, expectedY);

//     for (let i = 0; i < 10; i++) {

//         x = x * multiple;

//         results[reg.predict(x)] = x;

//     }

//     const keys = Object.keys(results).map(parseFloat);

//     y = keys.reduce((prev, curr) => Math.abs(curr - expectedY) < Math.abs(prev - expectedY) ? curr : prev);

//     x = results[y];

//     return { x, y };

// }

export const labAnalysisRegresionCharts = (data, activity, xPoint = "") => {

    if (!data) return {}

    data = clone(data) || [];

    const xType = xPoint ? xPoint : activity === "Running" ? 'speed' : 'power_raw';
    const x = _.map(data, xType).map(parseFloat);
    const hrAvg = _.map(data, 'hr').filter(Boolean).map(parseFloat);
    const vo2Avg = _.map(data, 'vo2relative').filter(Boolean).map(parseFloat);
    const vo2hrAvg = _.map(data, 'vo2absolute').filter(Boolean).map(parseFloat);
    const carbAvg = _.map(data, 'cho').filter(Boolean).map(parseFloat);
    const carbKcalAvg = _.map(data, 'eecho').filter(Boolean).map(parseFloat);
    const fatAvg = _.map(data, 'fat').map(parseFloat);
    const fatKcalAvg = _.map(data, 'eefat').filter(Boolean).map(parseFloat);
    const lactateAvg = _.map(data, 'lactate').filter(Boolean).map(parseFloat);
    const lactatePostAvg = _.map(data, 'lactatePost').filter(Boolean).map(parseFloat);

    let hr: any = null;
    let vo2: any = null;
    let vo2hr: any = null;
    let carb: any = null;
    let carbKcal: any = null;
    let fat: any = null;
    let fatKcal: any = null;
    let lactate: any = null;
    let lactatePost: any = null;
    if (x.length == hrAvg.length) {

        try {

            hr = chartRegressionLinear(x, hrAvg);

            data.map((row, index) => {
                data[index].reg_hr = fixedNum(hr.plot[index], 0);
            });

        } catch (error) {
            console.error(1, error);
        }

    }

    if (x.length == vo2Avg.length) {

        try {

            vo2 = chartRegressionLinear(x, vo2Avg);

            data.map((row, index) => {
                data[index].reg_vo2 = fixedNum(vo2.plot[index], 1);
            });

        } catch (error) {
            console.error(2, error);
        }

    }

    if (x.length == vo2hrAvg.length) {

        try {

            vo2hr = chartRegressionLinear(hrAvg, vo2hrAvg);

            data.map((row, index) => {
                data[index].reg_vo2absolute = fixedNum(vo2hr.plot[index], 1);
            });

        } catch (error) {
            console.error(3, error);
        }

    }


    if (x.length == carbAvg.length) {

        try {

            carb = chartRegressionPolynomial(x, carbAvg, 3);

            data.map((row, index) => {
                data[index].reg_cho = fixedNum(carb.plot[index], 1);
            });

        } catch (error) {
            console.error(4, error);
        }

    }

    if (x.length == carbKcalAvg.length) {

        try {

            carbKcal = chartRegressionPolynomial(x, carbKcalAvg, 3);

            data.map((row, index) => {
                data[index].reg_eecho = fixedNum(carbKcal.plot[index], 1);
            });

        } catch (error) {
            console.error(5, error);
        }

    }

    if (x.length == fatAvg.length) {

        try {

            fat = chartRegressionPolynomial(x, fatAvg, 3);
            data.map((row, index) => {
                data[index].reg_fat = fixedNum(fat.plot[index], 1);
            });

        } catch (error) {
            console.error(6, error);
        }

    }

    if (x.length == fatKcalAvg.length) {

        try {

            fatKcal = chartRegressionPolynomial(x, fatKcalAvg, 3);

            data.map((row, index) => {
                data[index].reg_eefat = fixedNum(fatKcal.plot[index], 1);
            });

        } catch (error) {
            console.error(7, error);
        }

    }

    if (x.length == lactatePostAvg.length) {

        try {

            lactatePost = chartRegressionPolynomial(x, lactatePostAvg), 3;

            data.map((row, index) => {
                data[index].reg_lactatePost = fixedNum(lactatePost.plot[index], 1);
            });

        } catch (error) {
            console.error(8, error);
        }

    }

    if (x.length == lactateAvg.length) {

        try {

            lactate = chartRegressionPolynomial(x, lactateAvg, 3);

            data.map((row, index) => {
                data[index].reg_lactate = fixedNum(lactate.plot[index], 1);
            });

        } catch (error) {
            console.error(9, error);
        }

    }


    return { mergedAvg: data, hr, vo2, vo2hr, carb, carbKcal, fat, fatKcal, lactatePost, lactate, x };

}