import { Col, FieldRadio, Row, Space } from '@dex/bubl-dash';
import React, { useEffect, useMemo } from 'react';

import styles from "./TestSessionsReport.module.scss";
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import TrainingCard from '../../../elements/TrainingCard/TrainingCard';
import TrainingZonesApi from '../../TrainingZones/TrainingZonesApi';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';

const TestSessionsReportTraining: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const activity = data.test.activity;

    const workouts = TrainingZonesApi.getWorkouts();

    const performance = data.test.lactate.zones
    const workoutUnit = data.test?.training?.workoutUnit;

    useEffect(() => {

        workouts.reset();

        if (!data.test.training?.zone) {
            return;
        }

        workouts.run({
            params: {
                id: data.test.training?.zone
            }
        })

    }, [data.test.training?.zone]);

    return useMemo(() => (

        <>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row gutter={16} edge>

                <Col col={{ xs: 24 }}>

                    {data.test?.training?.description &&
                        <ReportDesc
                            value={data.test?.training?.description}
                        />
                    }

                    <Space />

                    <TrainingCard
                        data={workouts.data}
                        zone={1}
                        activity={activity}
                        performance={performance[0]}
                        unit={workoutUnit}
                    />

                    <Space />

                    <TrainingCard
                        data={workouts.data}
                        activity={activity}
                        zone={2}
                        performance={performance[1]}
                        unit={workoutUnit}
                    />

                    <Space />

                    <TrainingCard
                        data={workouts.data}
                        activity={activity}
                        zone={3}
                        performance={performance[2]}
                        unit={workoutUnit}
                    />

                    <Space />

                    <TrainingCard
                        data={workouts.data}
                        activity={activity}
                        zone={4}
                        performance={performance[3]}
                        unit={workoutUnit}
                    />

                    <Space />

                    <TrainingCard
                        data={workouts.data}
                        activity={activity}
                        zone={5}
                        performance={performance[4]}
                        unit={workoutUnit}
                    />

                    <Space />

                    <TrainingCard
                        data={workouts.data}
                        activity={activity}
                        zone={6}
                        performance={performance[5]}
                        unit={workoutUnit}
                    />

                </Col>

            </Row>

        </>

    ), [data, workouts, performance, workoutUnit]);

}

export default TestSessionsReportTraining;