import { hash, loop } from '@dex/bubl-helpers';
import React, { useEffect, useState } from 'react';

import styles from "./TrainingCard.module.scss";

const TrainingCard: React.FC<TrainingCardProps> = (props) => {

    let { zone, performance, activity, unit } = props;

    const [data, setData]: any = useState(null);

    useEffect(() => {

        setData(props.data);

    }, [hash(props.data)])

    const labels = ['Rest', 'Endurance', 'Tempo', 'FTP', 'VO2max', 'Sprint'];

    const zones = {
        "1": {
            rpe: [1, 3],
            difficulty: 'Easy',
            level: 'Endurance',
            levelIndicator: 20,
            description: 'Sustainable for many Hours, Speaking is Easy',
            training: 'Builds endurance, increases fat burning, zone for recovery.',
            workout: '',
            icon: require('../../assets/images/TrainingCard/Zone 1.png')
        },
        "2": {
            rpe: [3, 5],
            difficulty: 'Moderate',
            level: 'Low Tempo',
            levelIndicator: 37,
            description: 'Sustainable for 2-3 hrs, Speaking Possible',
            training: 'Builds strong aerobic base, usually highest fat burning rate zone.',
            workout: '',
            icon: require('../../assets/images/TrainingCard/Zone 2.png')
        },
        "3": {
            rpe: [5, 7],
            difficulty: 'Somewhat Hard',
            level: 'High Tempo',
            levelIndicator: 43,
            description: 'Sustainable fo 1-2 hrs, Speaking requires Effort',
            training: 'Increases threshold/FTP, builds lactate tolerance.',
            workout: '',
            icon: require('../../assets/images/TrainingCard/Zone 3.png')
        },
        "4": {
            rpe: [7, 10],
            difficulty: 'Hard',
            level: 'Threshold',
            levelIndicator: 60,
            description: 'Sustainable for 15-60min. Speak only a few words at a time',
            training: 'Increases threshold/FTP, builds lactate tolerance and recovery.',
            workout: '',
            icon: require('../../assets/images/TrainingCard/Zone 4.png')
        },
        "5": {
            rpe: [8, 10],
            difficulty: 'Very Hard',
            level: 'VO2Max',
            levelIndicator: 79.5,
            description: 'Sustainable for 2-15min. Speaking extremely difficult',
            training: 'Increases aerobic capacity, improves economy and fitness.',
            workout: '',
            icon: require('../../assets/images/TrainingCard/Zone 5.png')
        },
        "6": {
            rpe: [9, 10],
            difficulty: 'Maximal',
            level: 'Anaerobic',
            levelIndicator: 91,
            description: 'Sustainable < 2min, Grunts & Groans Only',
            training: 'Increases explosive, glycolytic (anaerobic) and neuromuscular power / speed.',
            workout: '',
            icon: require('../../assets/images/TrainingCard/Zone 6.png')
        },
    }

    if (data && data.workouts) {

        loop(data.workouts, (workout, index) => {

            if (zones[(index + 1) + ""]) zones[(index + 1) + ""].workout = workout;

        });

    }

    const current = zones[zone || "1"];

    let BottomValue;
    let BottomUnit = unit == "Pace" ? "Min/km" : "kph";
    let speedPaceLowerVal = unit == "Pace" ? performance?.pace_adj_lower : performance?.powerLower;
    let speedPaceUpperVal = unit == "Pace" ? performance?.pace_adj_upper : performance?.powerUpper;
    let powerLowerVal = performance?.powerLower;
    let powerUpperVal = performance?.powerUpper;
    let hrLowerVal = performance?.hrLower;
    let hrUpperVal = performance?.hrUpper;

    const default999 = <>999 <span className={styles.valueTo}>To</span> 999</>
    const default999Time = <>99:99 <span className={styles.valueTo}>To</span> 99:99</>

    switch (activity) {
        case "Running":
            BottomValue = (
                <>
                    {speedPaceLowerVal && speedPaceUpperVal ? (
                        <>
                            {unit === "Pace" ? (
                                <>
                                    {zone !== 1 ? (
                                        <>
                                            {speedPaceUpperVal === ">" ? "<" : speedPaceUpperVal}
                                            {speedPaceLowerVal !== "<" &&
                                                speedPaceUpperVal !== ">" ? (
                                                <span className={styles.valueTo}> To </span>
                                            ) : (
                                                " "
                                            )}
                                            {speedPaceLowerVal ?? ""}
                                        </>
                                    ) : (
                                        <>
                                            {">"}
                                            {speedPaceUpperVal ?? ""}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {zone !== 1 ? (
                                        <>
                                            {speedPaceUpperVal === ">" ? ">" : speedPaceLowerVal}
                                            {speedPaceLowerVal !== "<" &&
                                                speedPaceUpperVal !== ">" ? (
                                                <span className={styles.valueTo}> To </span>
                                            ) : (
                                                " "
                                            )}
                                            {speedPaceUpperVal === ">" ? speedPaceLowerVal : speedPaceUpperVal}
                                        </>
                                    ) : (
                                        <>
                                            {"<"}
                                            {speedPaceUpperVal ?? ""}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        default999Time
                    )}
                </>
            );
            break;

        default:
            BottomValue = (
                <>
                    {powerLowerVal && powerUpperVal ?
                        <>
                            {zone !== 6 ?
                                <>
                                    {powerLowerVal} {powerLowerVal == "<" || "-" && <span className={styles.valueTo}>To</span>} {powerUpperVal ?? '>'}
                                </>
                                :
                                <>
                                    &gt; {powerLowerVal}
                                </>
                            }
                        </>
                        :
                        default999
                    }
                </>
            );

            BottomUnit = "Power";

            break;
    };

    let UpperValue = (
        <>
            {zone == 1 ?
                <>
                    {"<"} {hrUpperVal}
                </>
                :
                <>
                    {hrLowerVal && hrUpperVal ?
                        <>
                            {hrLowerVal} <span className={styles.valueTo}>To</span> {hrUpperVal ?? '>'}
                        </>
                        :
                        default999
                    }
                </>
            }
        </>
    );

    return (

        <>
            {data && current &&

                <div className={styles['zone-' + zone]}>

                    <div className={[styles.container, "zone-" + zone].join(" ")}>

                        <div className={styles.left}>

                            <img src={require(`../../assets/images/TrainingCard/mask-${activity ? activity : "Cycling"}.png`)}
                                alt="background Img"
                                className={styles.backgroundImage}
                            />

                            <div className={styles.innerLeft}>

                                <div className={styles.label}>
                                    Zone {zone}
                                </div>

                                <div className={styles.circle}>

                                    {zone !== 6 &&
                                        <div className={styles.topSemiCircle}>

                                            <div className={styles.topUnit}>

                                                BPM

                                            </div>

                                            <div className={styles.topValue}>

                                                {UpperValue}

                                            </div>
                                        </div>
                                    }

                                    <div className={styles.bottomSemiCircle}>

                                        <div className={styles.bottomValue}>

                                            {BottomValue}

                                        </div>

                                        <div className={styles.bottomUnit}>

                                            {BottomUnit}

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className={styles.middle}>

                            <div className={styles.middleTop}>

                                <div>

                                    <div className={styles.unit}>

                                        {current.difficulty} <span className={styles.ratingType}>/ {current.level} </span>

                                    </div>

                                    <div className={styles.rating}>

                                        RPE {current.rpe[0]} to {current.rpe[1]}

                                    </div>

                                </div>

                                <div className={styles.iconContainer}>

                                    <img src={current.icon}
                                        alt="icon"
                                        className={styles.icon}
                                    />

                                </div>

                            </div>

                            <div className={styles.middleBottom}>

                                <div className={styles.description}>

                                    {current.description}

                                </div>

                                <div className={styles.bar}>

                                    {labels?.map((label, index) => {
                                        return (
                                            <span className={styles.label} key={index}>

                                                <i className={styles.ticker}></i>
                                                {label}

                                            </span>
                                        )
                                    })}

                                    <div className={styles.pill}>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className={styles.right}>

                            <div className={styles.innerRight}>

                                <div className={styles.rightTop}>

                                    <div className={styles.rightTopLabel}>

                                        Training Effects

                                    </div>
                                    <div className={styles.rightTopDescription}>

                                        {current.training}

                                    </div>

                                </div>

                                <div className={styles.line}>

                                    <span></span>

                                </div>

                                <div className={styles.rightBottom}>

                                    <div className={styles.rightBottomLabel}>

                                        Workouts e.g.

                                    </div>
                                    <div className={styles.rightBottomDescription}>

                                        {current.workout}

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            }

        </>

    )

}

interface TrainingCardProps {
    [key: string]: any,
}

export default TrainingCard;