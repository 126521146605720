import React, { useEffect, useMemo, useState } from 'react';
import { Col, FieldCheckbox, FieldSelect, Heading, Line, Row, WhiteSpace } from '@dex/bubl-dash';
import { fixedNum, get, loop } from '@dex/bubl-helpers';
import ChartPowerVariation from '../../../elements/ChartBikeAerodynamic/ChartPowerVariation';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import { removeWhitespace } from '../../../functions/sharedGeneral';
import { calculateDragForce, calculatePowerWithDrive, calculateTotalResistance } from '../../../functions/sharedAerodynamicBike';
import styles from "./SessionsReport.module.scss";

const SessionsReportSpeedAndPower: React.FC<any> = (props: any) => {

    const { data, form, bike, index } = props;

    const bikeForm = get(form.values, `bikes[${index}]`);
    const prefix = `bikes[${index}]`;

    const types = bike?.positions?.map((position, i) => ({
        label: "Position " + (i + 1),
        value: "Position " + (i + 1),
    }));

    const [powerChartData, setPowerChartData] = useState<any[]>([]);

    useEffect(() => {

        loop(bikeForm.positions, (position, index) => {
            if (!position.comparePosition) form.handleChange({ reset: true, name: `${prefix}.positions[${index}].comparePosition`, value: `Position 1` });
        });

        const newPowerChartData = Array.from({ length: 60 / 5 + 1 }, () => ({}));

        loop(bikeForm?.positions, (position, index) => {
            const FTPPower = data.bio.ftpW;
            const comparePosition = removeWhitespace(position?.comparePosition);
            const forceGravity = get(form.values, `${prefix}.parameter.forceGravity`);
            const rollingResistanceDrag = get(form.values, `${prefix}.parameter.rollingResistanceDrag`);

            for (let i = 0; i <= 60; i += 5) {

                const forceDrag = calculateDragForce(i, bikeForm.parameter?.headwind, bikeForm.parameter?.airDensity, position.FSA, position.coefficientOfDrag);
                const totalResistance = calculateTotalResistance([forceGravity, rollingResistanceDrag, forceDrag]);

                const dragResistance = get(form.values, `${prefix}.positions[${index}].totalDragResistance`);
                const power = calculatePowerWithDrive(i, bikeForm.parameter?.headwind, totalResistance, bikeForm.parameter?.driveChangePowerLoss);
                const percentFTP = (power / FTPPower) * 100;

                const dataIndex = i / 5;
                newPowerChartData[dataIndex][`speed`] = i;
                newPowerChartData[dataIndex][`powerPosition${index + 1}`] = fixedNum(power, 0);
                newPowerChartData[dataIndex][`percentFTPPosition${index + 1}`] = fixedNum(percentFTP, 0);

            }
        })

        loop(newPowerChartData, (chart, i) => {

            loop(bikeForm?.positions, (position, index) => {

                const comparePosition = removeWhitespace(position?.comparePosition);

                //difference
                const power = chart[`powerPosition${index + 1}`];
                const percentFTP = chart[`percentFTPPosition${index + 1}`];

                newPowerChartData[i][`differencePercentFTPPosition${index + 1}`] = percentFTP - chart[`percentFTP${comparePosition}`];
                newPowerChartData[i][`differencePowerPosition${index + 1}`] = power - chart[`power${comparePosition}`];
            })

        })

        form.handleChange({ reset: true, name: `${prefix}.powerChartData`, value: newPowerChartData });

        setPowerChartData(newPowerChartData);

    }, [form.hash]);

    return useMemo(() => (

        <Row edge gutter={8}>

            <Col col={{ xs: 24 }}>

                <PanelChart
                    key={form.hash}
                    heading={"Power Variation At Different Speeds"}
                    data={powerChartData}
                    renderForm={(form, heading) => (

                        <Row edge gutter={8}>

                            <Col col={{ xs: 14 }}>
                                <h2 style={{ color: "white" }}>
                                    {heading}
                                </h2>
                            </Col>

                            <Col justify='end'>
                                <FieldCheckbox
                                    key={form.defaults.powerVariationPosition}
                                    form={form}
                                    name={"powerVariationPosition"}
                                    defaultValue={form.defaults.powerVariationPosition}
                                    options={types}
                                />
                            </Col>

                        </Row>

                    )}
                    formDefaults={{ powerVariationPosition: types ? types.map(type => type.value) : [] }}
                    renderChart={(chartType, chartData, values) => (
                        <ChartPowerVariation
                            data={chartData}
                            type={chartType}
                            allData={bikeForm}
                            filter={values}
                        />
                    )}
                />

            </Col>

            {bikeForm?.positions?.map((position, index) => {
                return (
                    <Col col={{ xs: 24 / bike?.positions.length }} className={styles.positionTable} key={index}>

                        <Heading className={"text-center"} space={3}>Position {index + 1}</Heading>
                        <Heading className={[styles["text-blue"], "text-center"].join(" ")} space={3}>{position.photoCaption || <WhiteSpace />}</Heading>

                        <Row gutter={8} edge>

                            <Col col={{ xs: index >= 1 ? 5 : 8 }}>
                                <WhiteSpace />
                            </Col>

                            <Col col={{ xs: index >= 1 ? 5 : 8 }}>
                                <WhiteSpace />
                            </Col>

                            <Col col={{ xs: index >= 1 ? 5 : 8 }}>
                                &nbsp;
                            </Col>

                            {index == 0 ? null : (
                                <Col col={{ xs: 9 }} className='text-center'>
                                    Difference
                                    <Line top={null} bottom={null} />
                                </Col>
                            )}

                            <Col col={{ xs: index >= 1 ? 5 : 8 }} className={[styles["text-light-blue"], 'text-center'].join(" ")}>
                                KPH
                                <Row gutter={8} edge>
                                    {powerChartData?.map((data, i) => (
                                        <Col col={{ xs: 24 }} key={i}>
                                            {data.speed}
                                        </Col>
                                    ))}
                                </Row>

                            </Col>

                            <Col col={{ xs: index >= 1 ? 5 : 8 }} className={[styles["text-secondary"], 'text-center'].join(" ")}>
                                W
                                <Row gutter={8} edge>
                                    {powerChartData?.map((data, i) => (
                                        <Col col={{ xs: 24 }} key={i}>
                                            {get(data, `powerPosition${index + 1}`)}
                                        </Col>
                                    ))}
                                </Row>

                            </Col>

                            <Col col={{ xs: index >= 1 ? 4 : 8 }} className='text-center'>
                                %FTP
                                <Row gutter={8} edge>
                                    {powerChartData?.map((data, i) => (
                                        <Col col={{ xs: 24 }} key={i}>
                                            {get(data, `percentFTPPosition${index + 1}`)} %
                                        </Col>
                                    ))}
                                </Row>

                            </Col>

                            {index == 0 ? null : (
                                <Col col={{ xs: 5 }} className={[styles["text-purple"], 'text-center'].join(" ")}>
                                    W
                                    <Row gutter={8} edge>
                                        {powerChartData?.map((chartData, i) => {
                                            return (
                                                <Col col={{ xs: 24 }} key={i}>
                                                    {get(chartData, `differencePowerPosition${index + 1}`)} W
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Col>
                            )}

                            {index == 0 ? null : (
                                <Col col={{ xs: 5 }} className={[styles["text-dark4"], 'text-center'].join(" ")}>
                                    %
                                    <Row gutter={8} edge>
                                        {powerChartData?.map((chartData, i) => (
                                            <Col col={{ xs: 24 }} key={i}>
                                                {get(chartData, `differencePercentFTPPosition${index + 1}`)} %
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            )}

                        </Row>

                    </Col>
                )
            })}

            {bikeForm?.positions?.map((position, index) => {

                const filteredTypes = types.filter((_, i) => i !== index);

                return (
                    <Col col={{ xs: 24 / bike?.positions.length }} key={index}>

                        {index >= 1 &&
                            <FieldSelect
                                form={form}
                                label="Compare To"
                                name={`${prefix}.positions[${index}].comparePosition`}
                                defaultValue={position.comparePosition}
                                options={filteredTypes}
                                wrapClass="manual-input inline-label"
                            />
                        }

                    </Col>
                )
            })}

        </Row>
    ), [props, form.hash])
}

export default SessionsReportSpeedAndPower;