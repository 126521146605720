import { Col, FieldRadio, InputTable, InputTableCell, InputTableRow, Row, Space } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';

import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import PanelChart from '../../../elements/PanelChart/PanelChart'
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import ReportChartSummary from '../../../elements/ReportChartSummary/ReportChartSummary';
import ChartProps from '../../../elements/Chart/Chart.Props';
import ChartEnergyFueling from '../../../elements/ChartTestSessionReport/ChartEnergyFueling';
import { labTestGetNutritionData } from '../../../functions/labTestData';
import ChartEnergy from '../../../elements/ChartLabTests/ChartEnergy';
import { MetricRanking } from '../../../elements/RatingScale/RatingScaleElements';
import { get, loop } from '@dex/bubl-helpers';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';

const TestSessionsReportEnergy: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const activity: string = data.test.activity;
    const intensity = data?.test?.intensity;
    const gender = data?.athlete?.gender;
    const isRunningPeak = activity == "Running" && intensity == "Peak";

    const [fuelUnit, setFuelUnit] = useState("Carb kcal");
    let unit;

    if (fuelUnit == "Carb kcal" || fuelUnit == "Fat kcal") {
        unit = "kcal"
    } else {
        unit = "g"
    };

    const [index, setIndex] = useState(data.history[0].date);

    const avgData = data.test.avgData;

    const tableData = labTestGetNutritionData(data.test, activity);

    const columns = [
        {
            key: "props",
            label: "Fuel-Burning Zones",
            style: { width: 440 }
        },
        {
            key: "firstCollumn",
            label: activity == "Cycling" ? "Power" : "Speed",
            desc: activity == "Cycling" ? "(W)" : "(kph)",
            style: { width: 300 }
        },
        {
            key: "secondCollum",
            label: "Carb Intake",
            desc: unit == "g" ? "(g/hr)" : "(kcal/hr)",
            style: { width: 300 }
        },
    ];

    const labels = {
        "Fat-Burning Primarily": "Fats Mostly",
        "Mixed Carb & Fat-Burning": "Carbs & Fats",
        "Carb-Burning Primarily": "Carbs Mostly"
    };

    const excludeNutrition = data.test?.energy?.nutritionUndetectable || [];

    const excludedUndetectableNutrition = (value) => {
        let conditional = true;

        loop(excludeNutrition, (nutrition, index) => {
            if (value == nutrition) conditional = false
        })

        return conditional
    }

    return useMemo(() => (

        <>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row gutter={16} edge>

                <Col col={{ xs: 24 }}>

                    <ReportHeading
                        text={"Fatmax"}
                    />

                    <PanelChart
                        heading={"Energy Burn"}
                        addZero={false}
                        data={data.allData}
                        renderForm={(form, heading) => (

                            <Row edge gutter={8}>

                                <Col col={{ xs: 7 }}>
                                    <h2 style={{ color: "white" }}>
                                        {heading}
                                    </h2>
                                </Col>

                                <Col col={{ xs: 11 }}>
                                    {isRunningPeak &&
                                        <FieldRadio
                                            form={form}
                                            name={"xAxis"}
                                            defaultValue={get(form.defaults, `xAxis`)}
                                            options={[
                                                { label: "Time", value: "time" },
                                                { label: "Grade %", value: "grade" },
                                                { label: "Speed (Max)", value: "maxSpeed" },
                                                { label: "Speed (Submax)", value: "submaxSpeed" },
                                            ]}
                                            wrapClass={"text-75"}
                                        />
                                    }
                                </Col>

                                <Col col={{ xs: 6 }}>
                                    <FieldRadio
                                        form={form}
                                        name={"useData"}
                                        defaultValue={get(form.defaults, `useData`)}
                                        options={[
                                            { label: "Carb Kcal/hr", value: "Carb kcal" },
                                            { label: "Fat Kcal/hr", value: "Fat kcal" },
                                            { label: "Carb g/hr", value: "Carb g" },
                                            { label: "Fat g/hr", value: "Fat g" },
                                        ]}
                                        wrapClass={"text-75"}
                                    />
                                </Col>

                            </Row>

                        )}
                        formDefaults={{ xAxis: "time", useData: "Carb kcal" }}
                        renderChart={(chartType, chartData, values) => (
                            <ChartEnergyFueling
                                activity={activity}
                                data={chartData}
                                date={data.recentDate}
                                index={index}
                                intensity={intensity}
                                xAxis={{
                                    interval: ChartProps.interval(activity, 'avg'),
                                }}
                                type="burn"
                                setFuelUnit={setFuelUnit(values.useData)}
                                isRunningPeak={isRunningPeak}
                                filters={values}
                            />
                        )}
                    />

                    <ReportChartSummary
                        key={index}
                        label={"Your FATMAX"}
                        valueKey={'fatmax'}
                        indexKey={"date"}
                        data={data.history}
                        type={"performance"}
                        index={index}
                        onIndexChange={setIndex}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <RatingScaleCard
                        metric={'Fatmax %VO2max - ' + activity}
                        value={data.test.energy.fatmaxVo2Percent}
                        form={form}
                        demographic={data.test.energy.mepRatingScale || data.demographic}
                        renderAfter={() => (
                            <>
                                <MetricRanking
                                    data={{
                                        title: "Fatmax Rate",
                                        suffix: activity == "Running" ? "kph" : "w"
                                    }}
                                    value={data.test.energy.fatmaxLoad}
                                    rating={" "}
                                    altUnit={{
                                        value: unit == "g" ? data.test.energy?.fatmaxFatg : data.test.energy?.fatmaxFatkcal,
                                        suffix: unit == "g" ? "g/hr" : "kcal/hr",
                                        position: "before",
                                        sep: "@"
                                    }}
                                />
                            </>
                        )}
                        gender={gender}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label="Fatmax"
                        value={data?.test.energy?.fatmaxDescription}
                    />

                </Col>

                <Col col={{ xs: 24 }}>

                    <Space />

                    <ReportHeading
                        text={"Metabolic Efficiency Point (MEP)"}
                    />

                    <PanelChart
                        heading={"Metabolic Efficiency - Carb% vs Fat%"}
                        addZero={false}
                        renderForm={(form, heading) => (

                            <Row edge gutter={8}>

                                <Col col={{ xs: 7 }}>
                                    <h2 style={{ color: "white" }}>
                                        {heading}
                                    </h2>
                                </Col>

                                <Col col={{ xs: 11 }}>
                                    {isRunningPeak &&
                                        <FieldRadio
                                            form={form}
                                            name={"xAxis"}
                                            defaultValue={get(form.defaults, `xAxis`)}
                                            options={[
                                                { label: "Time", value: "time" },
                                                { label: "Grade %", value: "grade" },
                                                { label: "Speed (Max)", value: "maxSpeed" },
                                                { label: "Speed (Submax)", value: "submaxSpeed" },
                                            ]}
                                            wrapClass={"text-75"}
                                        />
                                    }
                                </Col>

                                <Col col={{ xs: 6 }}>
                                    <FieldRadio
                                        form={form}
                                        name={"useData"}
                                        defaultValue={get(form.defaults, `useData`)}
                                        options={[
                                            { label: "All", value: "All" },
                                            { label: "Carb", value: "Carb" },
                                            { label: "Fat", value: "Fat" },
                                        ]}
                                        wrapClass={"text-75"}
                                    />
                                </Col>

                            </Row>

                        )}
                        formDefaults={{ xAxis: "time", useData: "All" }}
                        data={data.allData}
                        renderChart={(chartType, chartData, values) => (
                            <ChartEnergyFueling
                                activity={activity}
                                data={chartData}
                                date={data.recentDate}
                                index={index}
                                intensity={intensity}
                                xAxis={{
                                    interval: ChartProps.interval(activity, 'avg'),
                                }}
                                type="mep"
                                unit={chartType}
                                testData={data}
                                isRunningPeak={isRunningPeak}
                                filters={values}
                            />
                        )}
                    />

                    <ReportChartSummary
                        key={index}
                        label={"Your MEP"}
                        valueKey={'mep'}
                        indexKey={"date"}
                        data={data.history}
                        type={"performance"}
                        onIndexChange={setIndex}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <RatingScaleCard
                        metric={'MEP %VO2max - ' + activity}
                        value={data.test.energy.mepVo2Percent}
                        form={form}
                        demographic={data.test.energy.mepRatingScale || data.demographic}
                        renderAfter={() => (
                            <>
                                <MetricRanking
                                    data={{
                                        title: "ME Point",
                                        suffix: activity == "Running" ? "kph" : "w"
                                    }}
                                    value={data.test.energy.mepLoad}
                                    rating={" "}
                                />
                            </>
                        )}
                        gender={gender}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label="Metabolic Efficiency Point (MEP)"
                        value={data.test.energy?.mepDescription}
                    />

                </Col>

                <Col col={{ xs: 24 }}>

                    <Space />

                    <ReportHeading
                        text={"Fueling"}
                    />

                    <PanelChart
                        heading={"Carbohydrate Fueling"}
                        addZero={false}
                        data={avgData}
                        renderForm={(form, heading) => (

                            <Row edge gutter={8}>

                                <Col col={{ xs: 8 }}>
                                    <h2 style={{ color: "white" }}>
                                        {heading}
                                    </h2>
                                </Col>

                                <Col col={{ xs: 11 }}>
                                    {isRunningPeak &&
                                        <FieldRadio
                                            form={form}
                                            name={"xAxis"}
                                            defaultValue={get(form.defaults, `xAxis`)}
                                            options={[
                                                { label: "Time", value: "time" },
                                                { label: "Grade %", value: "grade" },
                                                { label: "Speed (Max)", value: "maxSpeed" },
                                                { label: "Speed (Submax)", value: "submaxSpeed" },
                                            ]}
                                            wrapClass={"text-75"}
                                        />
                                    }
                                </Col>

                                <Col col={{ xs: 5 }}>
                                    <FieldRadio
                                        form={form}
                                        name={"useData"}
                                        defaultValue={get(form.defaults, `useData`)}
                                        options={[
                                            { label: "kcal/hr", value: "kcal" },
                                            { label: "g/hr", value: "g" },
                                        ]}
                                        wrapClass={"text-75"}
                                    />
                                </Col>

                            </Row>

                        )}
                        formDefaults={{ xAxis: "time", useData: "kcal" }}
                        renderChart={(chartType, chartData, values) => (
                            <ChartEnergy
                                activity={activity}
                                data={chartData}
                                xAxis={{
                                    interval: ChartProps.interval(activity, 'avg'),
                                }}
                                testData={data}
                                intensity={intensity}
                                type="fueling"
                                setFuelUnit={setFuelUnit(values.useData)}
                                isRunningPeak={isRunningPeak}
                                filters={values}
                            />
                        )}
                    />
                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <InputTable
                        columns={columns}
                    >

                        {(tableData || []).map((row, index) => {

                            return (

                                <React.Fragment key={index}>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                            <><strong>{labels[row.zone]}</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 300, paddingLeft: 12 }}>

                                            {activity == "Cycling" ? row.power_raw : row.speed}

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 300, paddingLeft: 12 }}>

                                            {excludedUndetectableNutrition(row.zone) &&
                                                <>
                                                    {unit == "g" ? row.refuel : row.eerefuel}
                                                </>
                                            }

                                        </InputTableCell>

                                    </InputTableRow>

                                </React.Fragment>
                            )

                        })}

                    </InputTable>

                </Col>

                <Col col={{ xs: 24, md: 12 }}>
                    <ReportDesc
                        label="The Carbohydrate"
                        value={data?.test.energy?.carbDescription}
                    />
                </Col>

            </Row>

        </>

    ), [data, tableData]);

}

export default TestSessionsReportEnergy;