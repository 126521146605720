import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import InputTable, { InputTableCell, InputTableRow } from '@dex/bubl-dash/src/lib/components/InputTable/InputTable';
import { Button, ButtonRow, FieldCheckbox, FieldUpload, Space, useMountEffect, useUpdateEffect } from '@dex/bubl-dash';
import { fixedNum, get, loop } from '@dex/bubl-helpers';
import { percentageOf } from '../../../functions/sharedTests';
import { FSACalculation, meterSquareCalculation } from '../../../functions/sharedAerodynamic';
import { removeWhitespace } from '../../../functions/sharedGeneral';
import { calculateDragForce, calculatePowerWithDrive, calculateTotalResistance } from '../../../functions/sharedAerodynamicBike';

const SessionsEditTabsBikePositions: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data, setKey } = props;

    useMountEffect(() => {

        if (!bike.positions) {
            form.handleChange({ reset: true, name: `${prefix}.positions[0]`, value: {} })
        }

    });

    const metricColumns = get(form.values, `${prefix}.positions`, []).map((item, index) => {
        return { label: `Position ${index + 1}`, key: index }
    })

    const handleAddPosition = () => {

        const trial = [...bike.positions, {}];

        form.handleChange({ reset: true, name: `${prefix}.positions`, value: trial });

    };

    const handleDeletePosition = (index) => {

        const trial = bike.positions.filter((trial, i) => i !== index);

        form.handleChange({ reset: true, name: `${prefix}.positions`, value: trial });

    };

    const handleCalculation = (index) => {

        const item = bike.positions[index];

        form.handleChange({ reset: true, name: `${prefix}.positions[${index}].squarePerPixel`, value: meterSquareCalculation(item.calibrationDistance, item.calibrationPixel) })
        form.handleChange({ reset: true, name: `${prefix}.positions[${index}].FSA`, value: FSACalculation(item?.imagePixel, item.squarePerPixel) })
        form.handleChange({ reset: true, name: `${prefix}.positions[${index}].percentFSA`, value: percentageOf(FSACalculation(item?.imagePixel, item.squarePerPixel), bike.FSA) })
    };

    useUpdateEffect(() => {

        loop(bike.positions, (position, index) => {
            if (!position.comparePosition) form.handleChange({ reset: true, name: `${prefix}.positions[${index}].comparePosition`, value: `Position 1` });
        });

        const newPowerChartData = Array.from({ length: 60 / 5 + 1 }, () => ({}));

        loop(get(form.values, `${prefix}.positions`), (position, index) => {

            const FTPPower = data.bio.ftpW;
            const comparePosition = removeWhitespace(position?.comparePosition);
            const forceGravity = get(form.values, `${prefix}.parameter.forceGravity`);
            const rollingResistanceDrag = get(form.values, `${prefix}.parameter.rollingResistanceDrag`);

            for (let i = 0; i <= 60; i += 5) {

                const forceDrag = calculateDragForce(i, bike.parameter?.headwind, bike.parameter?.airDensity, position.FSA, position.coefficientOfDrag);
                const totalResistance = calculateTotalResistance([forceGravity, rollingResistanceDrag, forceDrag]);

                const power = calculatePowerWithDrive(i, bike.parameter?.headwind, totalResistance, bike.parameter?.driveChangePowerLoss);
                const percentFTP = (power / FTPPower) * 100;

                const dataIndex = i / 5;
                newPowerChartData[dataIndex][`speed`] = i;
                newPowerChartData[dataIndex][`powerPosition${index + 1}`] = fixedNum(power, 0);
                newPowerChartData[dataIndex][`percentFTPPosition${index + 1}`] = fixedNum(percentFTP, 0);

            }
        })

        loop(newPowerChartData, (chart, i) => {

            loop(bike?.positions, (position, index) => {

                const comparePosition = removeWhitespace(position?.comparePosition);

                //difference
                const power = chart[`powerPosition${index + 1}`];
                const percentFTP = chart[`percentFTPPosition${index + 1}`];

                newPowerChartData[i][`differencePercentFTPPosition${index + 1}`] = percentFTP - chart[`percentFTP${comparePosition}`];
                newPowerChartData[i][`differencePowerPosition${index + 1}`] = power - chart[`power${comparePosition}`];
            })

        })

        form.handleChange({ reset: true, name: `${prefix}.powerChartData`, value: newPowerChartData });

    }, [form.hash]);

    return useMemo(() => (

        <Row gutter={8} edge={true}>
            <Button
                label="Add Position"
                size='small'
                type='faded'
                onClick={handleAddPosition}
            />
            <Col col={{ xs: 24 }}>

                <InputTable
                    isTableContext={false}
                    columns={[
                        { key: "title" },
                        ...metricColumns
                    ]}
                >

                    <InputTableRow>

                        <InputTableCell>
                            <p>Enable Position</p>
                        </InputTableCell>

                        {bike.positions?.map((column, index) => (

                            <InputTableCell key={index}>
                                {index >= 0 &&
                                    <FieldCheckbox
                                        form={form}
                                        label={""}
                                        name={`${prefix}.positions[${index}].enablePosition`}
                                        defaultValue={column.enablePosition}
                                        singular={true}
                                        options={[
                                            { label: "", value: "yes" },
                                        ]}
                                    />
                                }

                            </InputTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>
                            <p>Add Photo</p>
                        </InputTableCell>

                        {bike.positions?.map((column, index) => (

                            <InputTableCell key={index}>
                                {index >= 0 &&
                                    <FieldUpload
                                        button={true}
                                        form={form}
                                        label={""}
                                        name={`${prefix}.positions[${index}].photo`}
                                        defaultValue={column.photo}
                                        required={false}
                                        folder='admin-media'
                                        multiple={false}
                                        accept='image/*'
                                    />
                                }

                                <Space />

                                {column.photo &&
                                    <FieldText
                                        form={form}
                                        label={"Caption"}
                                        name={`${prefix}.positions[${index}].photoCaption`}
                                        defaultValue={column.photoCaption}
                                        required={false}
                                        wrapClass={"manual-input"}
                                    />
                                }

                            </InputTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>
                            <p>Image Pixels</p>
                        </InputTableCell>

                        {bike.positions?.map((column, index) => (

                            <InputTableCell key={index}>

                                {index >= 0 &&
                                    <FieldNumber
                                        form={form}
                                        label={""}
                                        name={`${prefix}.positions[${index}].imagePixel`}
                                        defaultValue={column.imagePixel}
                                        wrapClass={"manual-input"}
                                    />
                                }

                            </InputTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>
                            <p>Calibration Pixels</p>
                        </InputTableCell>

                        {bike.positions?.map((column, index) => (

                            <InputTableCell key={index}>

                                {index >= 0 &&
                                    <FieldNumber
                                        form={form}
                                        label={""}
                                        name={`${prefix}.positions[${index}].calibrationPixel`}
                                        defaultValue={column.calibrationPixel}
                                        wrapClass={"manual-input"}
                                    />
                                }

                            </InputTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>
                            <p>Calibration Distance</p>
                        </InputTableCell>

                        {bike.positions?.map((column, index) => (

                            <InputTableCell key={index}>

                                {index >= 0 &&
                                    <FieldNumber
                                        form={form}
                                        label={""}
                                        name={`${prefix}.positions[${index}].calibrationDistance`}
                                        defaultValue={column.calibrationDistance}
                                        wrapClass={"manual-input"}
                                        suffix=" m"
                                    />
                                }

                            </InputTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>
                            <p>m² per pixels</p>
                        </InputTableCell>

                        {bike.positions?.map((column, index) => (

                            <InputTableCell key={index}>

                                {index >= 0 &&
                                    <FieldNumber
                                        form={form}
                                        label={""}
                                        name={`${prefix}.positions[${index}].squarePerPixel`}
                                        defaultValue={column.squarePerPixel}
                                        disabled
                                        suffix=" m²"
                                        dynamicValue
                                    />
                                }

                            </InputTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>
                            <p>Frontal Surface Area</p>
                        </InputTableCell>

                        {bike.positions?.map((column, index) => (

                            <InputTableCell key={index}>

                                {index >= 0 &&
                                    <FieldNumber
                                        form={form}
                                        label={""}
                                        name={`${prefix}.positions[${index}].FSA`}
                                        defaultValue={column.FSA}
                                        wrapClass={"green-input"}
                                        suffix=" m²"
                                        dynamicValue
                                    />
                                }

                            </InputTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell>
                            <p>% of Standing FSA</p>
                        </InputTableCell>

                        {bike.positions?.map((column, index) => (

                            <InputTableCell key={index}>

                                {index >= 0 &&
                                    <FieldNumber
                                        form={form}
                                        label={""}
                                        name={`${prefix}.positions[${index}].percentFSA`}
                                        defaultValue={column.percentFSA}
                                        disabled
                                        suffix=" %"
                                        dynamicValue
                                    />
                                }

                            </InputTableCell>

                        ))}

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell />

                        {bike.positions?.map((column, index) => (

                            <InputTableCell key={index}>

                                <ButtonRow>
                                    <Button
                                        label="Calculate"
                                        size='small'
                                        type='light'
                                        onClick={handleCalculation.bind(null, index)}
                                    />
                                    <Button
                                        label="Delete Position"
                                        size='small'
                                        type='faded'
                                        onClick={handleDeletePosition.bind(null, index)}
                                    />
                                </ButtonRow>

                            </InputTableCell>

                        ))}

                    </InputTableRow>

                </InputTable>

            </Col>

            <Line />

        </Row >


    ), [props, form.hash, metricColumns]);

}

export default SessionsEditTabsBikePositions;
