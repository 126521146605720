import { Col, FieldRadio, InputTable, InputTableCell, InputTableRow, Row, Space } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';

import styles from "./TestSessionsReportMuscle.module.scss";
import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import PanelChart from '../../../elements/PanelChart/PanelChart'
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import ReportChartSummary from '../../../elements/ReportChartSummary/ReportChartSummary';
import ChartMuscleLactate from '../../../elements/ChartTestSessionReport/ChartMuscleLactate';
import ChartProps from '../../../elements/Chart/Chart.Props';
import ChartMuscleOxygen from '../../../elements/ChartTestSessionReport/ChartMuscleOxygen';
import { labTestGetPrefZones } from '../../../functions/labTestData';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';
import { get } from '@dex/bubl-helpers';

const TestSessionsReportMuscle: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const activity = data.test.activity;
    const intensity = data?.test?.intensity;
    const gender = data.athlete.gender;
    const isRunningPeak = activity === "Running" && intensity === "Peak";

    const [index, setIndex] = useState(data.history[0].date);

    const columns = [
        {
            key: "props",
            label: "Zones",
            style: { width: 120 }
        },
        {
            key: "firstCollumn",
            label: "Lactate mmol/l",
            style: { width: 120 }
        },
        {
            key: "secondCollum",
            label: "Muscle O2 SMO2%",
            style: { width: 120 }
        },
        {
            key: "thirdCollum",
            label: "Intensity Range W",
            style: { width: 120 }
        },
    ];

    const labels = {
        "1": "Endurance",
        "2": "Tempo",
        "3": "Threshold",
        "4": "VO2max",
        "5": "Maximum",
    };

    const zone = data.test.lactate.zones;

    const endurance = zone["0"] || {};
    const threshold = zone["3"] || {};
    const maximum = zone["5"] || {};

    return useMemo(() => (

        <>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row gutter={16} edge>

                <Col col={{ xs: 24, md: 24 }}>

                    <ReportHeading
                        text={"Lactate Profile"}
                    />

                    <PanelChart
                        heading={"Lactate and RPE"}
                        addZero={false}
                        renderForm={(form, heading) => (

                            <Row edge gutter={8}>

                                <Col col={{ xs: 8 }}>
                                    <h2 style={{ color: "white" }}>
                                        {heading}
                                    </h2>
                                </Col>

                                <Col col={{ xs: 11 }}>
                                    {isRunningPeak &&
                                        <FieldRadio
                                            form={form}
                                            name={"xAxis"}
                                            defaultValue={get(form.defaults, `xAxis`)}
                                            options={[
                                                { label: "Time", value: "time" },
                                                { label: "Grade %", value: "grade" },
                                                { label: "Speed (Max)", value: "maxSpeed" },
                                                { label: "Speed (Submax)", value: "submaxSpeed" },
                                            ]}
                                            wrapClass={"text-75"}
                                        />
                                    }
                                </Col>

                                <Col col={{ xs: 5 }}>
                                    <FieldRadio
                                        form={form}
                                        name={"useData"}
                                        defaultValue={get(form.defaults, `useData`)}
                                        options={[
                                            { label: "Lactate", value: "Lactate" },
                                            { label: "RPE", value: "RPE" },
                                        ]}
                                        wrapClass={"text-75"}
                                    />
                                </Col>

                            </Row>

                        )}
                        formDefaults={{ xAxis: "time", useData: "Lactate" }}
                        data={data.allData}
                        renderChart={(chartType, chartData, values) => (
                            <ChartMuscleLactate
                                activity={activity}
                                data={chartData}
                                index={index}
                                date={data.recentDate}
                                intensity={intensity}
                                xAxis={{
                                    interval: ChartProps.interval(activity, 'avg'),
                                }}
                                testData={data.test}
                                isRunningPeak={isRunningPeak}
                                filters={values}
                            />
                        )}
                    />

                    <ReportChartSummary
                        label={"Your Lactate Profile"}
                        valueKey={'perfThreshold'}
                        indexKey={"date"}
                        data={data.history}
                        type={"performance"}
                        onIndexChange={setIndex}
                    />

                </Col>

                <Col col={{ xs: 12 }}>

                    <InputTable className={styles.table}>

                        <InputTableRow>

                            <InputTableCell>
                                <p className={styles.blueText}>
                                    {activity == "Running" ? "Threshold Speed Range" : "Threshold Power Range"}
                                </p>
                            </InputTableCell>

                            <InputTableCell>
                                <strong className={styles.text}>
                                    <>{isFinite(threshold.powerLower) ? threshold.powerLower : '-'} to {isFinite(threshold.powerUpper) ? threshold.powerUpper : '-'}  {activity == "Running" ? "kph" : "W"}</>
                                </strong>
                            </InputTableCell>

                        </InputTableRow>

                        <InputTableRow>

                            <InputTableCell>
                                <p className={styles.blueText}>
                                    {activity == "Running" ? "Functional Threshold Speed" : "Functional Threshold Range"}
                                </p>
                            </InputTableCell>

                            <InputTableCell>
                                <strong className={styles.text}>
                                    {data.test.lactate?.measures[0]?.ftp_speed} {activity == "Running" ? "kph" : "W"}
                                </strong>
                            </InputTableCell>

                        </InputTableRow>

                    </InputTable>

                </Col>

                <Col col={{ xs: 12 }}>

                    <ReportDesc
                        label="Lactate Profile"
                        value={data.test.lactate.lactateDescription}
                    />

                </Col>

                <Col col={{ xs: 24, md: 24 }}>

                    <Space />

                    <ReportHeading
                        text={"Muscle Oxygen Profile (SMO2)"}
                    />

                    <PanelChart
                        heading={"Muscle Oxygen"}
                        addZero={false}
                        renderForm={(form, heading) => (

                            <Row edge gutter={8}>

                                <Col col={{ xs: 13 }}>
                                    <h2 style={{ color: "white" }}>
                                        {heading}
                                    </h2>
                                </Col>

                                <Col col={{ xs: 11 }}>
                                    {isRunningPeak &&
                                        <FieldRadio
                                            form={form}
                                            name={"xAxis"}
                                            defaultValue={get(form.defaults, `xAxis`)}
                                            // label={"X-Axis"}
                                            options={[
                                                { label: "Time", value: "time" },
                                                { label: "Grade %", value: "grade" },
                                                { label: "Speed (Max)", value: "maxSpeed" },
                                                { label: "Speed (Submax)", value: "submaxSpeed" },
                                            ]}
                                            wrapClass={"text-75"}
                                        />
                                    }
                                </Col>

                            </Row>

                        )}
                        formDefaults={{ xAxis: "time" }}
                        data={data.allData}
                        renderChart={(chartType, chartData, values) => (
                            <ChartMuscleOxygen
                                activity={activity}
                                data={chartData}
                                index={index}
                                date={data.recentDate}
                                intensity={intensity}
                                xAxis={{
                                    interval: ChartProps.interval(activity, 'avg'),
                                }}
                                isRunningPeak={isRunningPeak}
                                filters={values}
                            />
                        )}
                    />

                    <ReportChartSummary
                        label={"Your Peak SMO2"}
                        valueKey={'smo2Min'}
                        indexKey={"date"}
                        data={data.history}
                        type={"performance"}
                        inverted={true}
                        onIndexChange={setIndex}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <RatingScaleCard
                        metric={'SMO2 Rating'}
                        value={data.test.muscle.a?.smo2Min}
                        form={form}
                        demographic={data.test.vo2.engineSizeScale || data.demographic}
                        gender={gender}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label="Muscle Oxygen"
                        value={data.test.muscle.smo2Description}
                    />

                </Col>

                {/* <Col col={{ xs: 24, md: 24 }}>

                    <ReportHeading
                        text={"Zone Identification"}
                    />

                    <InputTable
                        columns={columns}
                    >

                        {(tableData || []).map((row, index) => {

                            return (

                                <React.Fragment key={index}>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 440, paddingLeft: 12 }}>
                                            <><strong>{labels[row.zone]}</strong></>
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>

                                            {row.lac}

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>

                                            {row.smo2}

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>

                                            {row.speed_power}

                                        </InputTableCell>

                                    </InputTableRow>

                                </React.Fragment>
                            )

                        })}

                    </InputTable>

                </Col> */}

            </Row>

        </>

    ), [data, index]);

}

export default TestSessionsReportMuscle;