import { Col, FieldRadio, Heading, Row, Space } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';

import RatingScaleCard from '../../../elements/RatingScale/RatingScaleCard';
import PanelChart from '../../../elements/PanelChart/PanelChart'
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import ReportChartSummary from '../../../elements/ReportChartSummary/ReportChartSummary';
import { labTestGetPeakEconomy, labTestGetStrides } from '../../../functions/labTestData';
import ChartPerformance from '../../../elements/ChartTestSessionReport/ChartPerformance';
import ChartPerfEconomy from '../../../elements/ChartTestSessionReport/ChartPerfEconomy';
import { fixedNum, get } from '@dex/bubl-helpers';
import ReportTestSummary from '../../../elements/ReportTestSummary/ReportTestSummary';
import { fancyTimeFormat } from '../../../functions/sharedChart';
import ReportRunTimes from '../../../elements/ReportRunTimes/ReportRunTimes';
import ChartProps from '../../../elements/Chart/Chart.Props';
import { calculateMinPerKm } from '../../../functions/sharedTests';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';
import ChartPerformanceSpeedGrade from '../../../elements/ChartTestSessionReport/ChartPerformanceSpeedGrade';

const TestSessionsReportPerformance: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const intensity = data?.test?.intensity;
    const activity: string = data.test.activity;
    const isRunningPeak = activity === "Running" && intensity === "Peak";
    const gender = data.athlete.gender;

    const [index, setIndex] = useState(data.history[0].date);
    const [economyindex, setEconomyIndex] = useState(data.history[0].date);

    const avgData = data.test.avgData;

    const zones = data.test.lactate.zones;
    const anaerobic = zones["4"] || {};
    const threshold = zones["3"] || {};
    const ftp = data.test.lactate.measures[0];
    const vo2Max = data.test.lactate.measures[1];

    const peakValue = data.test.sumAvgData?.calc_economy?.avg;
    const peakEconomy = labTestGetPeakEconomy(peakValue, data.test?.avgData);
    const strides = labTestGetStrides(data.test?.manualData);
    const anaerobicPerKg = fixedNum(vo2Max?.vo2max_speed / data.bio?.weight, 1);
    const thresholdPerKg = fixedNum(ftp?.ftp_speed / data.bio?.weight);

    return useMemo(() => (

        <>
            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            {activity == "Running" &&
                <Row gutter={16} edge>

                    <Col col={{ xs: 24 }}>

                        <ReportHeading
                            text={"Speed, Pace and Stride"}
                        />

                        <PanelChart
                            heading={"Stride Rate and Length"}
                            renderForm={(form, heading) => (

                                <Row edge gutter={8}>

                                    <Col col={{ xs: 13 }}>
                                        <h2 style={{ color: "white" }}>
                                            {heading}
                                        </h2>
                                    </Col>

                                    <Col col={{ xs: 11 }}>
                                        {isRunningPeak &&
                                            <FieldRadio
                                                form={form}
                                                name={"xAxis"}
                                                defaultValue={get(form.defaults, `xAxis`)}
                                                options={[
                                                    { label: "Time", value: "time" },
                                                    { label: "Grade %", value: "grade" },
                                                    { label: "Speed (Max)", value: "maxSpeed" },
                                                    { label: "Speed (Submax)", value: "submaxSpeed" },
                                                ]}
                                                wrapClass={"text-75"}
                                            />
                                        }
                                    </Col>

                                </Row>

                            )}
                            formDefaults={{ xAxis: "time" }}
                            data={avgData}
                            renderChart={(chartType, chartData, values) => (

                                <>
                                    <ChartPerformance
                                        data={chartData}
                                        activity={activity}
                                        intensity={data?.test?.intensity}
                                        xAxis={{
                                            interval: 0
                                        }}
                                        isRunningPeak={isRunningPeak}
                                        filters={values}
                                    />
                                </>

                            )}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <ReportChartSummary
                            label={"Your Max Power"}
                            valueKey={'maxVo2Speed'}
                            indexKey={"date"}
                            data={data.history}
                            type={"performance"}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <ReportChartSummary
                            label={"Your Threshold Power"}
                            valueKey={'perfThreshold'}
                            indexKey={"date"}
                            data={data.history}
                            type={"performance"}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <RatingScaleCard
                            metric={"Performance - Running - Anaerobic"}
                            value={anaerobic.powerUpper}
                            form={form}
                            demographic={data.test.performance.powerThresholdScale || data.demographic}
                            split={{
                                metric: "Performance - Running - Threshold",
                                value: ftp?.ftp_speed,
                                altUnit: {
                                    value: calculateMinPerKm(ftp?.ftp_speed),
                                    suffix: "min/km",
                                }
                            }}
                            altUnit={{
                                value: calculateMinPerKm(anaerobic.powerUpper),
                                suffix: "min/km",
                                sep: " / "
                            }}
                            gender={gender}
                        />

                        <Space height={10} />

                        <ReportDesc
                            label={"Max and Threshold Speeds"}
                            value={data.test?.performance?.description}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <Heading space={false} className={"text-center"}>Estimated Run Times</Heading>

                        <Space height={8} />

                        <ReportRunTimes
                            items={[
                                {
                                    distance: 1.5,
                                    zone: zones["5"],
                                },
                                {
                                    distance: 5,
                                    zone: zones["4"],
                                },
                                {
                                    distance: 10,
                                    zone: zones["3"],
                                },
                                {
                                    distance: 21,
                                    zone: zones["2"],
                                },
                                {
                                    distance: 42,
                                    zone: zones["1"],
                                }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <Space />

                        <ReportHeading
                            text={"Running Economy"}
                        />

                        <PanelChart
                            heading={"Running Economy"}
                            data={data.allData}
                            renderForm={(form, heading) => (

                                <Row edge gutter={8}>

                                    <Col col={{ xs: 13 }}>
                                        <h2 style={{ color: "white" }}>
                                            {heading}
                                        </h2>
                                    </Col>

                                    <Col col={{ xs: 11 }}>
                                        {isRunningPeak &&
                                            <FieldRadio
                                                form={form}
                                                name={"xAxis"}
                                                defaultValue={get(form.defaults, `xAxis`)}
                                                options={[
                                                    { label: "Time", value: "time" },
                                                    { label: "Grade %", value: "grade" },
                                                    { label: "Speed (Max)", value: "maxSpeed" },
                                                    { label: "Speed (Submax)", value: "submaxSpeed" },
                                                ]}
                                                wrapClass={"text-75"}
                                            />
                                        }
                                    </Col>

                                </Row>

                            )}
                            formDefaults={{ xAxis: "time" }}
                            renderChart={(chartType, chartData, values) => (

                                <>
                                    <ChartPerfEconomy
                                        data={chartData}
                                        activity={activity}
                                        index={index}
                                        date={data.recentDate}
                                        intensity={intensity}
                                        xAxis={{
                                            interval: ChartProps.interval(activity, 'avg'),
                                        }}
                                        isRunningPeak={isRunningPeak}
                                        filters={values}
                                    />
                                </>

                            )}
                        />

                        <ReportChartSummary
                            label={"Your Peak Running Economy"}
                            valueKey={'perfEconomy'}
                            indexKey={"date"}
                            data={data.history}
                            type={"performance"}
                            onIndexChange={setIndex}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <RatingScaleCard
                            metric={'Peak Running Economy'}
                            value={peakValue}
                            form={form}
                            demographic={data.test.performance.economyScale || data.demographic}
                            gender={gender}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <ReportDesc
                            label={"Running Economy"}
                            value={data.test?.performance?.economyDescription}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <Space />

                        <ReportHeading
                            text="Test Summary"
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <PanelChart
                            heading={"Test Speed and Grade"}
                            data={avgData}
                            renderChart={(chartType, chartData) => (

                                <>
                                    <ChartPerformanceSpeedGrade
                                        data={chartData}
                                        activity={activity}
                                        xAxis={{
                                            interval: 0
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Col>

                    <Col col={{ xs: 24, md: 12 }} justify='center'>

                        <ReportTestSummary
                            rows={[
                                {
                                    heading: "Duration",
                                    values: [
                                        {
                                            value: fancyTimeFormat(data.test.duration),
                                            suffix: "min",
                                        }
                                    ]
                                },
                                {
                                    heading: "Speed",
                                    values: [
                                        {
                                            value: get(data.test, "sumAvgData.speed.avg", "-"),
                                            suffix: "kph",
                                            label: "Avg"
                                        },
                                        {
                                            value: get(data.test, "sumAvgData.speed.max", "-"),
                                            suffix: "kph",
                                            label: "Max"
                                        }
                                    ]
                                },
                                {
                                    heading: "Grade",
                                    values: [
                                        {
                                            value: get(data.test, "sumAvgData.grade.avg", "-"),
                                            suffix: "%",
                                            label: "Avg"
                                        },
                                        {
                                            value: get(data.test, "sumAvgData.grade.max", "-"),
                                            suffix: "%",
                                            label: "Max"
                                        }
                                    ]
                                },
                                {
                                    heading: "Stride Frequency",
                                    values: [
                                        {
                                            value: strides.avg,
                                            suffix: "",
                                            label: "Avg"
                                        },
                                        {
                                            value: strides.max,
                                            suffix: "",
                                            label: "Max"
                                        }
                                    ]
                                }
                            ]}
                        />


                    </Col>

                </Row>
            }

            {activity == "Cycling" &&
                <Row gutter={16} edge>

                    <Col col={{ xs: 24 }}>

                        <ReportHeading
                            text={"Power & RPM During Test"}
                        />

                        <PanelChart
                            heading={"Power & RPM"}
                            types={[
                                {
                                    name: 'Power',
                                    label: "Power",
                                    data: data.allData
                                },
                                {
                                    name: 'Rpm',
                                    label: "Rpm",
                                    data: data.allData
                                }
                            ]}
                            defaultType="Power"
                            renderChart={(chartType, chartData) => (

                                <>
                                    {/* <ChartPerformance
                                        data={chartData}
                                        activity={activity}
                                        xAxisKey={"time"}
                                        xAxis={{
                                            interval: 0
                                        }}
                                        index={index}
                                        date={data.recentDate}
                                        chartType={chartType}
                                    /> */}
                                </>

                            )}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <ReportChartSummary
                            label={"Your Max Power"}
                            valueKey={'maxVo2Speed'}
                            indexKey={"date"}
                            data={data.history}
                            type={"performance"}
                            onIndexChange={setIndex}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <ReportChartSummary
                            label={"Your Threshold Power"}
                            valueKey={'perfThreshold'}
                            indexKey={"date"}
                            data={data.history}
                            type={"performance"}
                            onIndexChange={setIndex}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <RatingScaleCard
                            metric={"Performance - Cycling - Anaerobic"}
                            value={vo2Max?.vo2max_speed}
                            form={form}
                            demographic={data.test.performance.powerAnaerobicScale || data.demographic}
                            split={{
                                metric: "Performance - Cycling - Anaerobic / Kg",
                                value: anaerobicPerKg,
                            }}
                            gender={gender}
                        />

                        <Space height={10} />

                        <RatingScaleCard
                            metric={'Performance - Cycling - Threshold'}
                            value={ftp?.ftp_speed}
                            form={form}
                            demographic={data.test.performance.powerThresholdScale || data.demographic}
                            split={{
                                metric: 'Performance - Cycling - Threshold / Kg',
                                value: thresholdPerKg,
                            }}
                            gender={gender}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <ReportDesc
                            label={"Absolute and Relative Power"}
                            value={data.test.performance.description}
                        />

                        <Space height={8} />

                        <Heading space={false} className={"text-center"}>Test Summary</Heading>

                        <ReportTestSummary
                            rows={[
                                {
                                    heading: "Duration",
                                    values: [
                                        {
                                            value: fancyTimeFormat(data.test.duration),
                                            suffix: "min",
                                        }
                                    ]
                                },
                                {
                                    heading: "Power (W)",
                                    values: [
                                        {
                                            value: fixedNum(get(data.test, "sumAvgData.power_raw.avg", "-"), 0),
                                            suffix: "",
                                            label: "Avg"
                                        },
                                        {
                                            value: fixedNum(get(data.test, "sumAvgData.power_raw.max", "-"), 0),
                                            suffix: "",
                                            label: "Max"
                                        }
                                    ]
                                },
                                {
                                    heading: "RPM",
                                    values: [
                                        {
                                            value: get(data.test, "sumAvgData.rpm.avg", "-"),
                                            suffix: "",
                                            label: "Avg"
                                        },
                                        {
                                            value: get(data.test, "sumAvgData.rpm.max", "-"),
                                            suffix: "",
                                            label: "Max"
                                        }
                                    ]
                                },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <Space />

                        <ReportHeading
                            text={"Cycling Economy"}
                        />

                        <PanelChart
                            heading={"Cycling Economy"}
                            data={data.allData}
                            renderChart={(chartType, chartData) => (

                                <>
                                    <ChartPerfEconomy
                                        data={chartData}
                                        activity={activity}
                                        index={economyindex}
                                        date={data.recentDate}
                                        xAxis={{
                                            interval: ChartProps.interval(activity, 'avg'),
                                        }}
                                    />
                                </>

                            )}
                        />

                        <ReportChartSummary
                            label={"Your Average Cycling Economy"}
                            valueKey={'perfEconomy'}
                            indexKey={"date"}
                            data={data.history}
                            type={"performance"}
                            onIndexChange={setEconomyIndex}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <RatingScaleCard
                            metric={'Peak Cycling Economy'}
                            value={peakValue}
                            // altUnit={{
                            //     value: fixedNum(peakEconomy.power_raw, 0),
                            //     suffix: "w",
                            //     sep: "@"
                            // }}
                            form={form}
                            demographic={data.test.performance.economyScale || data.demographic}
                            gender={gender}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <ReportDesc
                            label={"Cycling Economy"}
                            value={data.test.performance.economyDescription}
                        />

                    </Col>

                </Row>
            }
        </>

    ), [data, index, economyindex]);

}

export default TestSessionsReportPerformance;