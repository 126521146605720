import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldHidden, FieldNumber, FieldSelect, FieldSwitch, FieldText, FieldTextArea, InputTable, InputTableCell, InputTableRow, Line, Seperator, Space } from '@dex/bubl-dash';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { get, loop, parseNum, set } from '@dex/bubl-helpers';

const RatingScalesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const [gender, setGender] = useState("male");

    const columns = [
        {
            key: "label",
            label: "Label",
            style: { width: 100 }
        },
        {
            key: "hidden",
            label: "Hide Label",
            style: { width: 80 }
        },
        {
            key: "untrained",
            label: "Recreational",
            alignMiddle: true,
            desc: "Min - Max",
            style: { width: 100 },
            number: true,
        },
        {
            key: "trained",
            label: "Competitive",
            alignMiddle: true,
            desc: "Min - Max",
            style: { width: 100 },
            number: true,
        },
        {
            key: "elite",
            label: "Pro",
            alignMiddle: true,
            desc: "Min - Max",
            style: { width: 100 },
            number: true,
        },
        {
            key: "all",
            label: "All",
            alignMiddle: true,
            desc: "Min - Max",
            style: { width: 100 },
            number: true,
        }
    ];

    const oppositeGender = gender === "male" ? "female" : "male";

    const handleMaxChange = useCallback((index, gender, key, value) => {

        form.handleChange({ name: `steps[${index + 1}].${gender}.${key}.min`, value: value, reset: true, silent: true });

    }, [form.hash]);

    const handleCopy = useCallback((values) => {

        let rows = get(form.values, "steps", [])

        loop(rows, (row, index) => {

            rows[index] = rows[index] || {};

            let key = `[${index}]`;

            if (gender) key += `.${gender === "male" ? "female" : "male"}`;

            if (values) key += `.${values}`;

            set(rows, `${key}.min`, get(row, `[${gender}].[${values}].min`));
            set(rows, `${key}.max`, get(row, `[${gender}].[${values}].max`));

        });

    }, [form.values, gender])

    return useMemo(() => (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <Panel
                heading={"Rating Scale Details"}
            >
                <Row gutter={8} edge={true} className='test'>

                    <Col col={{ xs: 24, sm: 12 }}>

                        <FieldSelect
                            form={form}
                            name="metric"
                            label="Metric"
                            required={true}
                            defaultValue={form.defaults.metric}
                            options={[
                                { value: "Body Fat", label: "Body Fat" },
                                { value: "SOS", label: "SOS" },
                                { value: "BMI", label: "BMI" },
                                { value: "Waist-to-Hip Ratio", label: "Waist-to-Hip Ratio" },
                                { value: "Glycogen Load", label: "Glycogen Load" },
                                { value: "Glycogen Load per kg Muscle Mass", label: "Glycogen Load per kg Muscle Mass" },
                                { value: "Glycogen Load - Cycling", label: "Glycogen Load - Cycling" },
                                { value: "Glycogen Load - Running", label: "Glycogen Load - Running" },
                                { value: "SMO2 Rating", label: "SMO2 Rating" },
                                { value: "SMO2 Drop", label: "SMO2 Drop" },
                                { value: "VO2 Max", label: "VO2 Max" },
                                { value: "Engine Size", label: "Engine Size" },
                                { value: "Peak VO2-HR", label: "Peak VO2-HR" },
                                { value: "Fatmax Rating - Cycling", label: "Fatmax Rating - Cycling" },
                                { value: "Fatmax Rating - Running", label: "Fatmax Rating - Running" },
                                { value: "Fatmax %VO2max - Cycling", label: "Fatmax %VO2max - Cycling" },
                                { value: "Fatmax %VO2max - Running", label: "Fatmax %VO2max - Running" },
                                { value: "MEP Rating - Cycling", label: "MEP Rating - Cycling" },
                                { value: "MEP Rating - Running", label: "MEP Rating - Running" },
                                { value: "MEP %VO2max - Cycling", label: "MEP %VO2max - Cycling" },
                                { value: "MEP %VO2max - Running", label: "MEP %VO2max - Running" },
                                { value: "Breathing Rate - Max", label: "Breathing Rate - Max" },
                                { value: "Breathing Rate - Min", label: "Breathing Rate - Min" },
                                { value: "Breathing Rate - Avg", label: "Breathing Rate - Avg" },
                                { value: "Breathing Depth - Max", label: "Breathing Depth - Max" },
                                { value: "Breathing Depth - Min", label: "Breathing Depth - Min" },
                                { value: "Breathing Depth - Avg", label: "Breathing Depth - Avg" },
                                { value: "Minute Ventilation - Small Height", label: "Minute Ventilation - Small Height" },
                                { value: "Minute Ventilation - Medium Height", label: "Minute Ventilation - Medium Height" },
                                { value: "Minute Ventilation - Large Height", label: "Minute Ventilation - Large Height" },
                                { value: "Tidal Volume - Small Height", label: "Tidal Volume - Small Height" },
                                { value: "Tidal Volume - Medium Height", label: "Tidal Volume - Medium Height" },
                                { value: "Tidal Volume - Large Height", label: "Tidal Volume - Large Height" },
                                { value: "Breathing Efficiency - Peak", label: "Breathing Efficiency - Peak" },
                                { value: "Breathing Efficiency - % Time", label: "Breathing Efficiency - % Time" },
                                { value: "Performance - Running - Endurance", label: "Performance - Running - Endurance" },
                                { value: "Performance - Running - Tempo", label: "Performance - Running - Tempo" },
                                { value: "Performance - Running - Threshold", label: "Performance - Running - Threshold" },
                                { value: "Performance - Running - VO2max", label: "Performance - Running - VO2max" },
                                { value: "Performance - Running - Anaerobic", label: "Performance - Running - Anaerobic" },
                                { value: "Performance - Cycling - Endurance", label: "Performance - Cycling - Endurance" },
                                { value: "Performance - Cycling - Tempo", label: "Performance - Cycling - Tempo" },
                                { value: "Performance - Cycling - Threshold", label: "Performance - Cycling - Threshold" },
                                { value: "Performance - Cycling - VO2max", label: "Performance - Cycling - VO2max" },
                                { value: "Performance - Cycling - Anaerobic", label: "Performance - Cycling - Anaerobic" },
                                { value: "Performance - Cycling - Endurance / Kg", label: "Performance - Cycling - Endurance / Kg" },
                                { value: "Performance - Cycling - Tempo / Kg", label: "Performance - Cycling - Tempo / Kg" },
                                { value: "Performance - Cycling - Threshold / Kg", label: "Performance - Cycling - Threshold / Kg" },
                                { value: "Performance - Cycling - VO2max / Kg", label: "Performance - Cycling - VO2max / Kg" },
                                { value: "Performance - Cycling - Anaerobic / Kg", label: "Performance - Cycling - Anaerobic / Kg" },
                                { value: "Peak Cycling Economy", label: "Peak Cycling Economy" },
                                { value: "Peak Running Economy", label: "Peak Running Economy" },
                                { value: "Breathing At Rest - Capacity", label: "Breathing At Rest - Capacity" },
                                { value: "Breathing At Rest - Strength", label: "Breathing At Rest - Strength" },
                                { value: "Blood Glucose", label: "Blood Glucose" },
                                { value: "Resting Heart Rate", label: "Resting Heart Rate" },
                                { value: "Resting Blood Pressure - Systolic", label: "Resting Blood Pressure - Systolic" },
                                { value: "Resting Blood Pressure - Diastolic", label: "Resting Blood Pressure - Diastolic" },
                                { value: "Metabolism", label: "Metabolism" },
                                { value: "Fuel Burner", label: "Fuel Burner" },
                                { value: "Fluid Loss Rating", label: "Fluid Loss Rating" },
                                { value: "Sodium Loss Rating", label: "Sodium Loss Rating" },
                                { value: "Average Rehydration", label: "Average Rehydration" },
                                { value: "Vlamax", label: "Vlamax" },
                                { value: "Sprint Power 5 sec", label: "Sprint Power 5 sec" },
                                { value: "Sprint Power/kg 5 sec", label: "Sprint Power/kg 5 sec" },
                                { value: "Sprint Power 20 sec", label: "Sprint Power 20 sec" },
                                { value: "Sprint Power/kg 20 sec", label: "Sprint Power/kg 20 sec" },
                                { value: "Sprint Speed 5 sec", label: "Sprint Speed 5 sec" },
                                { value: "Sprint Pace 5 sec", label: "Sprint Pace 5 sec" },
                                { value: "Sprint Speed 20 sec", label: "Sprint Speed 20 sec" },
                                { value: "Sprint Pace 20 sec", label: "Sprint Pace 20 sec" },
                                { value: "Training Stimulus", label: "Training Stimulus" },
                                { value: "Fitness Level", label: "Fitness Level" },
                                { value: "Body Stress", label: "Body Stress" },
                                { value: "Toe Off Area", label: "Toe Off Area" },
                                { value: "Heel Whip", label: "Heel Whip" },
                                { value: "Arm Swing", label: "Arm Swing" },
                                { value: "Foot Inclination", label: "Foot Inclination" },
                                { value: "Leg Inclination", label: "Leg Inclination" },
                                { value: "Heel Strike Orientation", label: "Heel Strike Orientation" },
                                { value: "Foot Pronation Rate", label: "Foot Pronation Rate" },
                                { value: "Foot Direction", label: "Foot Direction" },
                                { value: "Foot Strike Audio", label: "Foot Strike Audio" },
                                { value: "Knee Angle", label: "Knee Angle" },
                                { value: "Leg Angle", label: "Leg Angle" },
                                { value: "Leg Cross", label: "Leg Cross" },
                                { value: "Knee Shifting", label: "Knee Shifting" },
                                { value: "Torso Rotation", label: "Torso Rotation" },
                                { value: "Elbow Position", label: "Elbow Position" },
                                { value: "Torso Lean", label: "Torso Lean" },
                                { value: "Pelvic Tilt", label: "Pelvic Tilt" },
                                { value: "Pelvic Drop", label: "Pelvic Drop" },
                                { value: "Rating And Changes", label: "Rating And Changes" },
                                { value: "Foot Load", label: "Foot Load" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12 }}>

                        <FieldText
                            form={form}
                            name="title"
                            label="Title"
                            defaultValue={form.defaults.title}
                            placeholder={"optional"}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12 }}>

                        <FieldText
                            form={form}
                            name="suffix"
                            label="Unit"
                            defaultValue={form.defaults.suffix}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12 }}>

                        <FieldNumber
                            form={form}
                            name="decimalPlaces"
                            label="Decimal Places"
                            defaultValue={form.defaults.decimalPlaces}
                        />

                    </Col>

                    <Col col={{ xs: 12, sm: 12 }}>

                        <FieldRadio
                            button
                            form={form}
                            name="displayAs"
                            label="Display As"
                            defaultValue={form.defaults.displayAs}
                            options={[
                                { value: "scale", label: "Scale" },
                                { value: "steps", label: "Steps" },
                                { value: "levels", label: "Levels" },
                            ]}
                            required
                        />

                    </Col>

                    <Col col={{ xs: 12, sm: 12 }}>

                        <FieldRadio
                            button
                            form={form}
                            name="bestScore"
                            label="Best Score is"
                            defaultValue={form.defaults.bestScore}
                            required
                            options={[
                                { value: "highest", label: "Highest" },
                                { value: "lowest", label: "Lowest" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Line />
                    </Col>

                    <Col col={{ xs: 24, sm: 12 }}>

                        <FieldTextArea
                            form={form}
                            label={"Description"}
                            name={"description"}
                            defaultValue={form.defaults?.description}
                            rows={4}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12 }}>

                        <FieldText
                            form={form}
                            name="source"
                            label="Source"
                            defaultValue={form.defaults.source}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Table"}
                    top={'auto'}
                    bottom={'auto'}
                    tabs={[
                        { name: "male", label: "Male" },
                        { name: "female", label: "Female" },
                    ]}
                    activeTab={gender}
                    setTab={setGender}
                />

                <InputTable
                    key={gender}
                    columns={columns}
                >

                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => {

                        const row = get(form.values, `steps[${index}]`) || {};

                        return (

                            <InputTableRow key={index}>

                                {columns.map((column) => (

                                    <InputTableCell key={column.key}>

                                        {column.key === "label" &&
                                            <FieldText
                                                form={form}
                                                name={`steps[${index}].label`}
                                                defaultValue={row.label}
                                                wrapClass={"w-240"}
                                            />
                                        }

                                        {get(form.values, `steps[${index}].label`) &&
                                            <>

                                                {column.key === "hidden" &&
                                                    <FieldSwitch
                                                        form={form}
                                                        name={`steps[${index}].hidden`}
                                                        defaultValue={row.hidden}
                                                    />
                                                }

                                                {column.number &&
                                                    <Row gutter={8} edge={true}>
                                                        <Col col={{ xs: 11 }} align="center">
                                                            <FieldNumber
                                                                form={form}
                                                                name={`steps[${index}].${gender}.${column.key}.min`}
                                                                defaultValue={get(row, `${gender}.${column.key}.min`, get(form.values, `steps[${index - 1}].${gender}.${column.key}.max`))}
                                                                wrapClass={"w-80"}
                                                                allowNegative={true}
                                                                key={index > 0 ? form.hash : undefined}
                                                                disabled={index > 0}
                                                                hidden={index > 0}
                                                            />
                                                            <FieldHidden
                                                                form={form}
                                                                name={`steps[${index}].${oppositeGender}.${column.key}.min`}
                                                                value={get(row, `${oppositeGender}.${column.key}.min`)}
                                                            />
                                                        </Col>
                                                        <Col col={{ xs: 2 }} align="center" justify='center'>
                                                            -
                                                        </Col>
                                                        <Col col={{ xs: 11 }} align="center">
                                                            <FieldNumber
                                                                form={form}
                                                                name={`steps[${index}].${gender}.${column.key}.max`}
                                                                defaultValue={get(row, `${gender}.${column.key}.max`)}
                                                                wrapClass={"w-80"}
                                                                allowNegative={true}
                                                                onChange={handleMaxChange.bind(null, index, gender, column.key)}
                                                            />
                                                            <FieldHidden
                                                                form={form}
                                                                name={`steps[${index}].${oppositeGender}.${column.key}.max`}
                                                                value={get(row, `${oppositeGender}.${column.key}.max`)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                }

                                            </>
                                        }

                                    </InputTableCell>

                                ))}

                            </InputTableRow>
                        )
                    })}

                    <InputTableRow>

                        <InputTableCell></InputTableCell>

                        <InputTableCell></InputTableCell>

                        <InputTableCell alignMiddle>
                            <Button
                                label={`copy to ${gender == "male" ? "female" : "male"}`}
                                onClick={handleCopy.bind(null, "untrained")}
                                type={"faded"}
                                size='small'
                            />
                        </InputTableCell>

                        <InputTableCell alignMiddle>
                            <Button
                                label={`copy to ${gender == "male" ? "female" : "male"}`}
                                onClick={handleCopy.bind(null, "trained")}
                                type={"faded"}
                                size='small'
                            />
                        </InputTableCell>

                        <InputTableCell alignMiddle>
                            <Button
                                label={`copy to ${gender == "male" ? "female" : "male"}`}
                                onClick={handleCopy.bind(null, "elite")}
                                type={"faded"}
                                size='small'
                            />
                        </InputTableCell>

                        <InputTableCell alignMiddle>
                            <Button
                                label={`copy to ${gender == "male" ? "female" : "male"}`}
                                onClick={handleCopy.bind(null, "all")}
                                type={"faded"}
                                size='small'
                            />
                        </InputTableCell>

                    </InputTableRow>

                </InputTable>

                <Space />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Panel>

        </Form>

    ), [form.hash, gender, props]);

}

export default RatingScalesCreateEditForm;
