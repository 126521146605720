import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { FieldRepeater, FieldTextArea, FieldUpload } from '@dex/bubl-dash';

const GaitsEditTabsRunPictures: React.FC<any> = (props: any) => {

    const { form, prefix, run, data } = props;

    return useMemo(() => (

        <FieldRepeater
            form={form}
            name={`${prefix}.pictures`}
            addButtonLabel={"Add Picture"}
        >

            {(run.pictures || []).map((row, n) => (

                <React.Fragment key={form.key + "-" + n}>

                    <Row gutter={16} edge={true} className='row-top-edge'>

                        <Col col={{ xs: 12 }}>

                            <FieldUpload
                                form={form}
                                name={`${prefix}.pictures[${n}].image`}
                                folder='admin-media'
                                label='Photo'
                                required={true}
                                defaultValue={row.image}
                                multiple={true}
                                limit={2}
                                accept='image/*'
                            />

                        </Col>

                        <Col col={{ xs: 12 }}>

                            <FieldTextArea
                                form={form}
                                name={`${prefix}.pictures[${n}].caption`}
                                label={"Caption"}
                                required={true}
                                defaultValue={row.caption}
                                rows={4}
                                wrapClass={"manual-input"}
                            />

                        </Col>

                    </Row>

                </React.Fragment>
            ))}

        </FieldRepeater>

    ), [form.hash, run]);

}

export default GaitsEditTabsRunPictures;
