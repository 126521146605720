import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { FieldSelect, FieldCheckbox, Form, Panel, Button, ButtonRow, Line, FieldSwitch, useMountEffect, FieldAutocomplete, FieldHidden, ButtonGroup, useUpdateEffect } from '@dex/bubl-dash';
import { clone, get, } from '@dex/bubl-helpers';
import { coachingGetLastWeek, getWeek } from '../../../functions/sharedCoaching';

const CoachingReportsForm: React.FC<any> = (props: any) => {

    const { form, mode, process, handleSubmit, data } = props;

    // const [sport, setSport]: any = useState([]);
    const { weeks, week } = getWeek(form, data);
    const { targetWeek } = coachingGetLastWeek(data, true);

    useMountEffect(() => {
        if (mode === "edit") return;

        const mainSport: any = get(data, "athlete.mainSport")

        form.handleChange({ reset: true, name: "sport", value: mainSport })
    })

    const pages = {
        main: [
            { value: "cover", label: "Cover" },
            { value: "athlete-profile", label: "Athlete Details" },
            { value: "training-week", label: "Your Training This Week" },
            { value: "long-term-plan", label: "Long Term Plan" },
            { value: "tracking-training", label: "Tracking Training" },
            { value: "sport-specific", label: "Sport Specific" },
            { value: "events", label: "Events" },
            { value: "zones", label: "Zones" },
            { value: "comment", label: "Coach's Comment" }
        ],
        tracking: [
            { value: "volume", label: "Volume and Intensity" },
            { value: "workouts", label: "Workouts" },
            { value: "heartrate", label: "Heartrate" },
            { value: "self-ratings", label: "Self-Ratings" },
            { value: "fitness", label: "Fitness" },
            { value: "hydration", label: "Hydration and Fueling" },
        ],
        sport: [
            { value: "swim", label: "Swim" },
            { value: "bike", label: "Bike" },
            { value: "run", label: "Run" },
        ]
    }

    const toggleAllPages = useCallback((values, check = false) => {

        const _values = clone(values);

        if (check) {
            _values.pages = pages.main.map(page => page.value);
            _values.pagesTracking = pages.tracking.map(page => page.value);
            _values.pagesSport = pages.sport.map(page => page.value);
        } else {
            _values.pages = [];
            _values.pagesTracking = [];
            _values.pagesSport = [];
        }

        form.reset(_values);

    }, [form.key]);

    useUpdateEffect(() => {

        if (form.values?.type === "coach-basic-report") {

            const _values = clone(form.values);

            _values.pages = ["cover", "athlete-profile", "long-term-plan", "events", "zones", "comment"]

            form.reset(_values);

        }

    }, [form.values?.type]);

    return useMemo(() => (

        <Panel
            heading={mode === "edit" ? "Edit Report" : "Add New Report"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row edge gutter={8}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldSelect
                            form={form}
                            name='type'
                            defaultValue={form.defaults?.type}
                            label='Type'
                            options={[
                                { label: "Coach's Premium Report", value: "coach-premium-report" },
                                { label: "Coach's Basic Report", value: "coach-basic-report" }
                            ]}
                            required
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldAutocomplete
                            form={form}
                            name='purpose'
                            defaultValue={form.defaults?.purpose}
                            label='Purpose'
                            group={"coachingReport.Purpose"}
                            preload={true}
                            required
                            wrapClass={"manual-input"}
                            options={[
                                { label: "Athlete's Weekly Report", value: "Athlete's Weekly Report" },
                                { label: "Coach's Custom Report", value: "Coach's Custom Report" },
                            ]}
                            sort={false}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldAutocomplete
                            key={form.values?.sport}
                            dynamicKey
                            dynamicValue
                            form={form}
                            name='sport'
                            defaultValue={form.values?.sport}
                            label='Sport'
                            group='sport'
                            options={[
                                { label: "Triathlon", value: "triathlon" },
                                { label: "Duathlon", value: "duathlon" },
                                { label: "Cycling", value: "cycling" },
                                { label: "Running", value: "running" },
                                { label: "Swimming", value: "swimming" },
                                { label: "Multisport", value: "multisport" },
                                { label: "Other", value: "other" },
                            ]}
                            required
                            wrapClass={"manual-input"}
                            sort={false}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldSwitch
                            form={form}
                            name='fixedWeek'
                            defaultValue={form.defaults.fixedWeek}
                            label='Fixed To Latest Week'
                        />

                    </Col>

                    {!form.values.fixedWeek &&
                        <>
                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldSelect
                                    form={form}
                                    name='planId'
                                    defaultValue={form.defaults.planId}
                                    label='Plan'
                                    options={data?.planOptions}
                                    disabled={form.values.fixedWeek == true}
                                    required
                                    wrapClass={"manual-input"}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldSelect
                                    form={form}
                                    name='weekNo'
                                    defaultValue={form.defaults.weekNo}
                                    label='Week'
                                    options={weeks}
                                    disabled={(!form.values.planId || form.values.fixedWeek == true)}
                                    required
                                    wrapClass={"manual-input"}
                                />

                                <FieldHidden
                                    form={form}
                                    name='endDate'
                                    value={form.values.fixedWeek == true ? targetWeek?.endDate : week.endDate}
                                />

                            </Col>
                        </>
                    }

                </Row>

                <Row edge gutter={8}>

                    <Col col={{ xs: 24 }}>

                        <ButtonRow>

                            <Button
                                label={"Check All Pages"}
                                onClick={toggleAllPages.bind(null, form.values, true)}
                                size='small'
                                type='light'
                            />

                            <Button
                                label={"Uncheck All Pages"}
                                onClick={toggleAllPages.bind(null, form.values, false)}
                                size='small'
                                type='faded'
                            />
                        </ButtonRow>

                    </Col>

                    <Col col={{ xs: 24, sm: 6 }}>

                        <FieldCheckbox
                            key={form.key}
                            form={form}
                            label='Report Pages'
                            name={`pages`}
                            defaultValue={get(form.defaults, `pages`)}
                            options={pages.main}
                            list
                            required
                        />

                    </Col>

                    {form.values.pages?.includes("tracking-training") &&
                        <Col col={{ xs: 24, sm: 6 }}>

                            <FieldCheckbox
                                key={form.key}
                                form={form}
                                label='Tracking Training Pages'
                                name={`pagesTracking`}
                                defaultValue={get(form.defaults, `pagesTracking`)}
                                options={pages.tracking}
                                list
                                required
                            />

                        </Col>
                    }

                    {form.values.pages?.includes("sport-specific") &&
                        <Col col={{ xs: 24, sm: 6 }}>

                            <FieldCheckbox
                                key={form.key}
                                form={form}
                                label='Sport-Specific Pages'
                                name={`pagesSport`}
                                defaultValue={get(form.defaults, `pagesSport`)}
                                options={pages.sport}
                                list
                                required
                            />

                        </Col>
                    }

                    {form.values.pages?.includes("long-term-plan") &&
                        <Col col={{ xs: 24, sm: 8 }}>

                            <FieldCheckbox
                                key={form.key}
                                form={form}
                                label='Long Term Plan Pages'
                                name={`pagesPlan`}
                                defaultValue={get(form.defaults, `pagesPlan`)}
                                options={data?.planOptions}
                                list
                                required
                            />

                        </Col>
                    }

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    ), [form.hash, process.loading]);

}

export default CoachingReportsForm;
