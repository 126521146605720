import { Col, Line, Row, Space, useMountEffect } from '@dex/bubl-dash';
import React, { useMemo, useState } from 'react';

import styles from "./TestSessionsReportComment.module.scss";
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';

const TestSessionsReportComment: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const isSprintReport = ["sprint-running", "sprint-cycling"].includes(data.reportType);

    return useMemo(() => (

        <Row gutter={16} edge>

            <Col col={{ xs: 24 }}>

                <ReportDesc
                    label="Test Results "
                    value={data.summary.comments ? `\n\n ${data.summary.comments}` : ""}
                />

                <Space height={10} />

                <ReportDesc
                    label={`Going Forwards`}
                    value={data.summary.goingForward ? `\n\n ${data.summary.goingForward}` : ""}
                />

            </Col>

        </Row>

    ), [data]);

}

export default TestSessionsReportComment;